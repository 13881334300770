import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate,  useParams, Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

import WebApi from "@Services/WebApi";
import SocialMedia from "@Components/SocialMedia";
import Loading from "@Components/Loading";
import Regulamento from "@Components/Regulamento";
import HorarioFuncionamento from "@Components/HorarioFuncionamento";
import { useAuth } from "@Contexts/Auth";
import LogoF from "@Components/LogoF";

// import { Container } from './styles';

const Parceiro = (props) => {
  const { idParceiro }   = useParams();
  const navigate = useNavigate();
  const {
    parceiro,
    SetParceiro,
  } = useAuth();

  const [isLoading, setLoading] = useState(true);

  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  const isValidHttpUrl = (string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(string);
  }
  // Gera URL safe do site do parceiro
  // const [pURL, setPURL] = React.useState(() => {
  //   let link = parceiro.social.site;

  //   if (link === 'null' || link === null || link === undefined || link === 'undefined') {
  //     link = false;
  //   }
  //   if (link && (link.indexOf("http://") == -1 || link.indexOf("https://") == -1)) {
  //     link = 'https://' + link;
  //   }

  //   if (!isValidHttpUrl(link)) {
  //     link = false;
  //   }
  //   return link;
  // });
  //////////////////////////////////////////////////////////////////////////////////////
  // Components
  //////////////////////////////////////////////////////////////////////////////////////
  const Carregando = (<>
    <div className={styles.loading}>
      <div className="d-block text-center">
        <div className={styles.logo}>
          <img src="/assets/img/fidelizi_icone.png" width="30" alt="" />
          <Loading size={112} color={'#B487FE'}/>
        </div>
        <p className="m-0 text-purple-4 fs-10 fw-500">Carregando Estabelecimento...</p>
      </div>
    </div>
  </>);
  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  // Busca o parceiro
  useEffect(() => {
    setLoading(true);
    SetParceiro(false);
    WebApi.get(`/clientes/estabelecimentos/${idParceiro}`).then(res => {
      console.log(res.data.estabelecimento);
      SetParceiro(res.data.estabelecimento);
    }).catch(err => {
      Swal.fire("Oops!", "O programa de fidelidade neste estabelecimento foi encerrado ou não está disponível no momento.", "error");
      navigate(`/dashboard`);
    }).finally(() => setLoading(false));
  }, [idParceiro]);

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {isLoading && Carregando}
    {!isLoading && (<>
      <section className={styles.infoCliente}>
        <div className={styles.header}><img src={parceiro.identidade.tela_inicial} alt={parceiro.nome}/></div>
        <div className={styles.background}>
          <div className={styles.contents}>
            <div className="d-lg-flex d-block">
              <div className={styles.section}>
                <div className={styles.parceiroHead}>
                  <div className={styles.parceiroLogo}><img src={parceiro.identidade.logotipo} alt={'Logo ' + parceiro.nome} /></div>
                  <div className={styles.parceiroName}>
                    <div className={styles.overflow}>
                    <h1>{parceiro.nome}</h1>
                      <div className={styles.parceiroAddress}>
                        <address>{parceiro.endereco}, <br/> {parceiro.cidade}, {parceiro.estado}</address> 
                        {parceiro.telefone1 && <span> 
                          , Tel.: <NumberFormat 
                                    value={parceiro.telefone1} 
                                    displayType={'text'} 
                                    format={String(parceiro.telefone1).length == 10 ? "(##) ####-####" : "(##) #.####-####"} 
                                  />
                        </span> }
                      </div>
                      {/* {pURL && <><br/><a href={pURL} target="_blank" rel="noopener noreferrer nofollow">{pURL}</a></> } */}
                    </div>
                  </div>
                </div>
                <Outlet/>
              </div>
              <div className={styles.sidebar}>
                <div className={styles.parceiroCard}>
                  <div className={styles.squareFrame}>
                    <iframe 
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCMTn1LEkTtOQ3tpW3Ruf6kGN6poH0txAM&q=${parceiro.endereco},${parceiro.cidade},${
                      parceiro.estado}`}
                      allowFullScreen="allowFullScreen" 
                      loading="lazy"
                    />
                  </div>
                  <SocialMedia 
                    className={styles.parceiroSocial}
                    whatsapp={`https://wa.me/55${parceiro.telefone2}`}
                    blog={parceiro.social.blog}
                    youtube={parceiro.social.youtube}
                    vimeo={parceiro.social.vimeo}
                    facebook={parceiro.social.facebook}
                    twitter={parceiro.social.twitter} 
                    instagram={parceiro.social.instagram}
                    pinterest={parceiro.social.pinterest} 
                    linkedin={parceiro.social.linkedin}
                  />
                  <hr className="dashed"/>
                  <div className="text-center">
                    <Regulamento className="btn btn-bg-purple-3 text-white" parceiro={parceiro}/>
                  </div>

                  { parceiro.horario.tipo == 'personalizado' && <>
                    <hr className="dashed"/>
                    <div className="text-left">
                      <HorarioFuncionamento 
                        horarios={parceiro.horario}
                        mode={'complete'}
                      />
                    </div>
                  </> }
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>)}
  </>);
};

export default Parceiro;
