import Button from '@Components/Button';
import Tooltip from '@Components/Tooltip';
import WebApi from '@Services/WebApi';
import React from 'react';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import Swal from 'sweetalert2';
import styles from './styles.module.scss';

const INITIAL_DATA = {
  empresa_indicado: '',
  dono_indicado: '',
  ramo_indicado: '',
  email_indicado: '',
  telefone_indicado: '',
}

const ModalIndica = (props) => {
  const [isShow, setShowModal] = React.useState(false);
  const [formData, setFormData] = React.useState({ ...INITIAL_DATA });
  const [isSaving, setSaving] = React.useState(false);

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.empresa_indicado.trim() === '') { return Swal.fire('Oops!', "O campo Nome do Estabelecimento é obrigatório!", 'error'); }
    if (formData.dono_indicado.trim() === '') { return Swal.fire('Oops!', "O campo Nome do Dono/Proprietário é obrigatório!", 'error'); }
    if (formData.telefone_indicado.trim() === '' || formData.telefone_indicado.trim() === '55') { return Swal.fire('Oops!', "O campo Celular / WhatsApp do estabelecimento é obrigatório!", 'error'); }
    if (formData.telefone_indicado.trim().length < 13) { return Swal.fire('Oops!', "O celular digitado é inválido!", 'error'); }

    setSaving(true);
    setSaving(false);

    WebApi.post('/clientes/indicar',formData).then(res => {
      Swal.fire('Muito obrigado pela indicação!', 'Nossa equipe comercial entrará em contato com o Estabelecimento indicado por você.', 'success');
      setShowModal(false);
    }).catch(err => {
      Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ao enviar sua indicação, tente novamente!', 'error');
    }).finally(() => setSaving(false));
  }

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return ({ ...prev, [name]: value });
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    console.log(formData);
  }, [formData])
  React.useEffect(() => {
    if (isShow) {

    } else {
      setFormData({ ...INITIAL_DATA });
    }

  }, [isShow])

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {props?.children && React.cloneElement(props.children, { onClick: (e) => setShowModal(true) })}
    {!props?.children && (
      <Tooltip text="Detalhes da Simulação">
        <button className={`btn btn-action`} onClick={(e) => setShowModal(true)} >
          <i className="fa fa-clipboard"></i>
        </button>
      </Tooltip>
    )}

    <Modal
      show={isShow}
      centered
      contentClassName={styles.modal}
      onHide={() => setShowModal(false)}
      animation={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Indique o Fidelizi!</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className={styles.container}>
            <div className="form-group mb-3">
              <label>Nome do Estabelecimento<sup>*</sup></label>
              <input
                type="text"
                className="form-control"
                value={formData.empresa_indicado}
                onChange={handleInput}
                name="empresa_indicado"
              />
            </div>
            <div className="form-group mb-3">
              <label>Nome do Dono/Proprietário<sup>*</sup></label>
              <input
                type="text"
                className="form-control"
                value={formData.dono_indicado}
                onChange={handleInput}
                name="dono_indicado"
              />
            </div>
            <div className="form-group mb-3">
              <label>Ramo de atividade</label>
              <input
                type="text"
                className="form-control"
                value={formData.ramo_indicado}
                onChange={handleInput}
                name="ramo_indicado"
              />
            </div>
            <div className="form-group mb-3">
              <label>Email do estabelecimento</label>
              <input
                type="email"
                className="form-control"
                value={formData.email_indicado}
                onChange={handleInput}
                name="email_indicado"
              />
            </div>
            <div className="form-group mb-3">
              <label>Celular / WhatsApp do estabelecimento<sup>*</sup></label>
              <PhoneInput
                inputClass='form-control w-100'
                onlyCountries={['br']}
                country={'br'}
                value={formData.telefone_indicado}
                onChange={(e) => setFormData(prev => ({ ...prev, telefone_indicado: e }))}
                placeholder={'(99) 999999999'}
                countryCodeEditable={false}
                disableDropdown={true}
              />
            </div>
            <small className="text-right d-block text-gray-2"><sup>*</sup>obrigatório</small>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-block'>
          <div className={styles.container}>
            <Button className="btn btn-bg-purple-3 text-white w-100" loading={isSaving}>Enviar Indicação</Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  </>)
}

export default ModalIndica;