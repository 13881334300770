import React from 'react';
import styles from './styles.module.scss';
import { Modal } from 'react-bootstrap';

const Regulamento = ({parceiro, className}) => {
  const [show, setShow] = React.useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (<>
    <a className={className} onClick={handleShow}>
      Regulamento do Programa
    </a>

    <Modal show={show} fullscreen centered={true} onHide={handleClose} backdrop="static" size="xl" scrollable="true">
      <Modal.Header closeButton>
        <Modal.Title>Regulamento do Programa de {parceiro.nome}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.tipography} dangerouslySetInnerHTML={{__html: parceiro.regulamento}}/>
      </Modal.Body>
      <Modal.Footer>
        <a className="btn btn-bg-purple-3 text-white" onClick={handleClose}>Entendi!</a>
      </Modal.Footer>
    </Modal>
  </>)
}
  
export default Regulamento;