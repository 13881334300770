import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

const LoginBody = ({children}) => {

  return (
    <>
      <div className={styles['login-screen']}>
        <div className={`${styles['background-container']} d-flex align-items-center`}>
          <h1>
            QUANTO MAIS VOCÊ USA,
            <br className="mobile-hidden" /> <b>MAIS VOCÊ GANHA!</b>
          </h1>
          <div className={styles["background-image"]}>
            <img src="/assets/img/image-overlay.png" alt="" />
          </div>
        </div>
        <div className={`${styles['form-container']} d-flex flex-column text-center`}>
          <div className={styles["logo"]}>
            <a href="/">
              <img className="img-responsive mx-auto" src={'/assets/img/logo-fidelizi.png'} />
            </a>
          </div>
          <div className={styles["content"]}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginBody;