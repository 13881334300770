import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { Link, NavLink, generatePath, Outlet, useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";

import Footer from "@Components/Footer";
import Tooltip from "@Components/Tooltip";
import CheckIn from "@Components/CheckIn";
import LogoF from "@Components/LogoF";
import PartnerDropdown from "@Components/PartnerDropdown";
import { useAuth } from "@Contexts/Auth";
import { ResponseInterceptor } from "./Interceptor";


const Dashboard = (props) => {
  const { idParceiro } = useParams();

  const {
    Authenticate,
    Logout,
    user,
    SetUser,
    SetRede,
    SetParceiro,
    rede,
    parceiro
  } = useAuth();


  const [isLoading, setLoading] = useState(true);


  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////

  const handleLogout = () => {
    Logout();
  };

  //////////////////////////////////////////////////////////////////////////////////////
  // Components
  //////////////////////////////////////////////////////////////////////////////////////
  const Menu = () => {
    const options = {
      pontos  : 'Meus pontos',
      premios : 'Prêmios',
      brindes : 'Brindes',
      ofertas : 'Ofertas',
      indique : 'Indique & Ganhe',
      detalhes: 'Detalhes',
    }

    
    return (<>
      <Tooltip delay={1500} text={!parceiro ? 'Selecione um estabelecimento para liberar a navegação.' : null}>
        <ul className={`d-none d-lg-flex ${styles.navigation}`}>
          {Object.entries(options).map(key => (<React.Fragment key={key}>
            <li>
              {!parceiro && <div className={`${styles.link} ${styles.disabled}`}><span>{options[key[0]]}</span></div>}
              {parceiro && (<>
                <NavLink className={({isActive}) => isActive ? `${styles.link} ${styles.active}` : styles.link} to={generatePath(`/dashboard/:idParceiro/${key[0]}`,{idParceiro: parceiro?.id_parceiro})}>
                  <span>{options[key[0]]}</span>
                </NavLink>
              </>)}
            </li>
          </React.Fragment>))}
        </ul>
      </Tooltip>
      <ul className={`d-none d-lg-flex ${styles.navigation}`}>
        <li>
          <Tooltip text="Meus Dados">
            <NavLink to={'/dashboard/meus-dados'} className={({isActive}) => isActive ? `${styles.link} ${styles.active}` : styles.link}><i className="fa fa-gear"></i></NavLink>
          </Tooltip>
        </li>
      </ul>
    </>)
  }
  const Profile = () => {
    const options = {
      pontos  : 'Meus pontos',
      premios : 'Prêmios',
      brindes : 'Brindes',
      ofertas : 'Ofertas',
      indique : 'Indique & Ganhe',
      detalhes: 'Detalhes',
    }

    return (
      <div className={`d-flex align-items-center ${styles.profile} ms-auto`}>
        <div className={`${styles.shortcuts}`}>
          <Dropdown className="d-block d-md-none ">
            <Dropdown.Toggle className="btn btn-bg-purple-3 text-white pe-4">
              <span className="d-block pe-2"><i className="fa fa-bars"></i></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="drop-menu">
                <ul>
                  {Object.entries(options).map(key => (<React.Fragment key={key}>
                    <li>
                      {!parceiro && <div className={`${styles.link} ${styles.disabled}`}><span>{options[key[0]]}</span></div>}
                      {parceiro && (<>
                        <NavLink className={`py-2 py-lg-0 ${({isActive}) => isActive ? `${styles.link} ${styles.active}` : styles.link}`} to={generatePath(`/dashboard/:idParceiro/${key[0]}`,{idParceiro: parceiro?.id_parceiro})}>
                          <span>{options[key[0]]}</span>
                        </NavLink>
                      </>)}
                    </li>
                  </React.Fragment>))}
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <PartnerDropdown className={`d-none d-md-block text-white ${styles.dropdownNavbar}`} parceiros={rede} current={parceiro}/>
        </div>
        <Dropdown>
          <Dropdown.Toggle className={styles.loggedInUser} as="div">
            <div className={styles.avatar}><img src={user?.metadata?.avatar ? user?.metadata?.avatar : 'https://fidelizii.s3.sa-east-1.amazonaws.com/image/wizard-profile.png'} alt={user?.nome} /></div>
            <span className="d-none d-md-block ms-1 me-2">{user?.primeiro_nome}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu align={"end"} className={styles.userOptions}>
            <div className={`menu ${styles.menu}`}>
              <div className={styles.userInfo}>
                  <div className={`${styles.avatar}`}>
                      {user?.metadata?.avatar !== null ? (
                          <img src={user?.metadata?.avatar ? user?.metadata?.avatar:'https://fidelizii.s3.sa-east-1.amazonaws.com/image/wizard-profile.png'} alt={user?.nome}/>) : user?.iniciais}
                  </div>
                  <div className={styles.username}>
                      <div className={styles.title}>{user?.nome}</div>
                  </div>
                  <Dropdown.Toggle bsPrefix={`close ms-auto ${styles.close}`} as="div"/>
              </div>
              <div className="drop-menu">
                <ul>
                  <PartnerDropdown className={styles.dropdownMenu} parceiros={rede} current={parceiro}/>
                  <li><hr/></li>
                  <li><Link className="py-2 py-lg-0" to={'/dashboard'}><i className="fa fa-store"></i> Estabelecimentos</Link></li>
                  <li><Link className="py-2 py-lg-0" to={'/dashboard/meus-dados'}><i className="fa fa-user"></i> Meus Dados</Link></li>
                  <li><button className="py-2 py-lg-0" onClick={handleLogout}><i className="fa fa-power-off"></i> Desconectar</button></li>
                </ul>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    
    Authenticate().then(res => {
      SetUser(res.cliente);
      SetRede(res.rede);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      Logout({
        title: 'Oops',
        text: 'Sessão expirada!',
        icon: 'error'
      });
    });

  }, []);

  // Se não houver idParceiro, define false pra não dar erro nos menus e etc...
  useEffect(() => {
    if (!idParceiro) SetParceiro(false);
  },[idParceiro])

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <ResponseInterceptor/>
    <div className={styles.bg}/>
    {isLoading && <CheckIn/>}
    {!isLoading && (<>
      <div className={`animate fadeIn ${styles.body}`}>
        <nav className={styles.navbar} variant="yellow">
          <div className="container">
            <div className="d-flex align-items-center w-100">
              <Link to={"/dashboard"} className={styles.logo}>
              <img src="/assets/img/logo_round.png" width="40" alt="" />
              </Link>
              {/* <div className="d-none d-lg-flex"> */}
                <Menu/>
              {/* </div> */}
              <div className={styles.profileContainer}>
                <Profile/>
              </div>
            </div>
          </div>
        </nav>

        <div className={styles.contents}>
          <Outlet/>
        </div>

        <Footer/>
      </div>
    </>)}
  </>

  );
};

export default React.memo(Dashboard);
