import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import WebApi from "@Services/WebApi";
import ReCAPTCHA from "react-google-recaptcha";
import useStateWithValidation from "@Hooks/useStateWithValidation";
import FormCadastroCliente from "@Components/FormCadastroCliente";
import LoginBody from "@Components/LoginBody";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SwalLoading from "@Components/SwalLoading";
import {
  validarCPF,
  validarNomeCompleto,
  validarEmail,
  validarCelular,
  validarData,
  validarSexo
} from "@Services/Helpers";

function CadastroPage() {
  const cameFromExternal = !!sessionStorage.getItem("cpf");
  const navigate = useNavigate();

  const [cpf, setCpf, isCpfValid] = useStateWithValidation(validarCPF, sessionStorage.getItem("cpf") || "");
  const [nome, setNome, isNomeValid] = useStateWithValidation(validarNomeCompleto, '');
  const [email, setEmail, isEmailValid] = useStateWithValidation(validarEmail, '');
  const [sexo, setSexo, isSexoValid] = useStateWithValidation(validarSexo, '');
  const [sexoOutro, setSexoOutro] = useState('');
  const [dataNascimento, setDataNascimento, isDataNascValid] = useStateWithValidation(validarData, '');
  const [celular, setCelular, isCelularValid] = useStateWithValidation(validarCelular, '');
  const [cep, setCep, isCepValid] = useStateWithValidation((value) => value.replace(/\D/g, '').length === 8, '');
  const [endereco, setEndereco, isEnderecoValid] = useStateWithValidation((value) => value !== '', '');
  const [bairro, setBairro, isBairroValid] = useStateWithValidation((value) => value !== '', '');
  const [estado, setEstado, isEstadoValid] = useStateWithValidation((value) => value !== '', '');
  const [cidade, setCidade, isCidadeValid] = useStateWithValidation((value) => value !== '', '');
  const [recaptcha, setRecaptcha] = useState("");
  const [formIsValid, setFormValid] = useState(false);



  const modular = {
    questao_data_nascimento: 'obrigatorio',
    questao_celular: 'obrigatorio',
    questao_sexo: 'obrigatorio',
    questao_localizacao: 'obrigatorio',
  }

  const cliente = { cpf, nome, email, sexo, sexoOutro, dataNascimento, celular, cep, endereco, bairro, estado, cidade }

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleDadosCadastrais = (e) => {
    if (e.cpf.required) setCpf(e.cpf.value);
    if (e.nome_completo.required) setNome(e.nome_completo.value);
    if (e.email.required) setEmail(e.email.value);
    if (e.data_nascimento.required) setDataNascimento(e.data_nascimento.value);
    if (e.celular.required) setCelular(e.celular.value);
    if (e.sexo.required) setSexo(e.sexo.value);
    if (e.sexo_outro.required) setSexoOutro(e.sexo_outro.value);
    if (e.cep.required) setCep(e.cep.value);
    if (e.endereco.required) setEndereco(e.endereco.value);
    if (e.bairro.required) setBairro(e.bairro.value);
    if (e.estado.required) setEstado(e.estado.value);
    if (e.cidade.required) setCidade(e.cidade.value);
    setFormValid(e.formIsValid);
  }

  const handleCadastro = (e) => {
    e.preventDefault();

    if (!validateFields()) { return; }

    let data = {
      cpf,
      nome,
      email
    }


    data.nome = nome;
    data.email = email;
    data.cpf = cpf;
    data.cadastro = true;
    data.recaptcha = recaptcha;

    if (modular.questao_celular.includes('obrigatorio')) {
      data.celular = celular || '+55';
    }
    if (modular.questao_data_nascimento.includes('obrigatorio')) {
      data.data_nascimento = dataNascimento;
    }
    if (modular.questao_sexo.includes('obrigatorio')) {
      data.sexo = sexo;
      data.sexo_outro = sexoOutro;
    }
    if (modular.questao_localizacao.includes('obrigatorio')) {
      data.cep = cep;
      data.endereco = endereco;
      data.bairro = bairro;
      data.id_estado = estado;
      data.id_cidade = cidade;
    }

    SwalLoading('Enviando seus dados!', '');
    WebApi.post("/clientes/cadastro", data).then(res => {
      return Swal.fire({
        title: 'Parabéns! 🥳',
        html: "Seu cadastro foi realizado com sucesso! <br />" + res?.data?.message,
        icon: 'success'
      }).then(input => {
        sessionStorage.clear();
        sessionStorage.setItem("cpf", cpf)
        navigate("/");
      });
    }).catch(err => {
      return Swal.fire("Oops!", err.data.errors[0], 'error');
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  function validateFields() {
    if (!isCpfValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'O CPF digitado é inválido.',
        icon: 'error'
      });
      return false;
    }
    if (!isNomeValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'O nome digitado deve conter pelo menos um sobrenome.',
        icon: 'error'
      });
      return false;
    }

    if (!isEmailValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'O Email digitado é inválido.',
        icon: 'error'
      });
      return false;
    }

    if (modular.questao_celular.includes('obrigatorio') && !isCelularValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'O celular digitado é inválido.',
        icon: 'error'
      });
      return false;
    }

    if (modular.questao_localizacao.includes('obrigatorio')) {
      if (!isCepValid || !isEnderecoValid || !isBairroValid || !isCidadeValid || !isEstadoValid) {
        Swal.fire({
          title: 'Oops!',
          text: 'As informações do endereço digitadas estão inválidas,',
          icon: 'error'
        });
        return false;
      }
    }

    if (modular.questao_data_nascimento.includes('obrigatorio') && !isDataNascValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'A data de nascimento digitada é inválida.',
        icon: 'error'
      });
      return false;
    }

    if (modular.questao_sexo.includes('obrigatorio') && !isSexoValid) {
      Swal.fire({
        title: 'Oops!',
        text: 'O sexo selecionado é inválido.',
        icon: 'error'
      });
      return false;
    }

    if (modular.questao_sexo.includes('obrigatorio') && sexo === "O" && sexoOutro === '') {
      Swal.fire({
        title: 'Oops!',
        text : 'O gênero digitado não pode ser um valor vazio.',
        icon : 'error'
      });
      return false;
    }

    return true;
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <LoginBody>
      <div className="d-block">
        {cameFromExternal && <label className={styles.paraParticipar}>O CPF <b className="text-purple-3">{cpf}</b> ainda não participa do Fidelizi Faça já o seu cadastro!</label>}
        {!cameFromExternal && <label className={styles.paraParticipar}>Participe de vários programa de fidelidade e ganhe muitos beneficios em diversos estabelecimentos!</label>}

        <p className={`${styles.preencha} mt-2 mb-0`}>Preencha os campos abaixo para participar!</p>
        <p className="fs-11 text-gray-2">Já participa? <a className="text-purple-3" href="/"> Clique aqui. </a></p>

        <FormCadastroCliente className="mb-3 text-left" cliente={cliente} questoes={modular} onChange={handleDadosCadastrais} showCpf={true} />

        <div className="d-flex">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            name="recaptcha"
            onChange={(e) => setRecaptcha(e)}
            className={`mx-auto mb-3 ${styles.recaptcha}`}
          />
        </div>
        <button className="btn btn-gradient-purple w-100" onClick={(e) => handleCadastro(e)} disabled={!formIsValid || recaptcha === ''}>
          PARTICIPAR <i className="fa fa-angle-right"></i>
        </button>
        <p className="small text-center mt-2">
          Ao participar você concorda e aceita os <a className='text-purple-3' href="https://fidelizii.com.br/termos-de-uso/" target="_blank">termos de uso </a>
          e <a className='text-purple-3' href="https://fidelizii.com.br/politica-de-privacidade-e-cookies/" target="_blank">políticas de privacidade </a>
          do Fidelizi
        </p>
      </div>
    </LoginBody>
  </>);
}
export default CadastroPage;