import React from 'react';
import styles from './styles.module.scss';
import { Dropdown } from 'react-bootstrap';

interface ifcOptions {
  label: string;
  value: string|any;
}

interface ifcProps {
  options      : Array<ifcOptions>;
  prefix?      : string;
  icon?        : string;
  value?       : Array<string>;
  defaultValue?: Array<string>;
  initialValue?: Array<string>;
  onOpen?      : any;
  onClose?     : any;
  onShow?      : any;
  onHide?      : any;
  onSelect?    : any;
  onReset?     : any;
  showReset?   : boolean;
  showSearch?  : boolean;
  className?   : any;
}



const SelectList = ({options, prefix, icon, value, defaultValue, initialValue, onOpen, onClose, onShow, onHide, onSelect, onReset, showReset = false, showSearch = false, className}: ifcProps) => {
  const [values, setValues]      = React.useState<Array<string>>([]);
  const [search, setSearch]      = React.useState<string>('');
  const [temp, setTemp]          = React.useState<Array<string>>([]);
  const [isMenuOpen, toggleMenu] = React.useState<boolean>(false);

  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  const exibidos = () => {
    if (values.length === options.length) return "Todos";
    if (values.length === 0) return "Nenhum";
    return values.map((i) => options.find((o) => o.value === i)?.label).join(', ');
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleCheck = (e) => {
    const name:string = e.target.name;

    let items:Array<string> = [...values];
    
    if (items.indexOf(name) > -1) {
      items = items.filter((e) => e !== name );
    } else {
      items.push(name);
    }
    setValues(items)

    if (typeof onSelect === 'function') onSelect(items);
  }

  const handleToggle = (isOpen,event) => {
    toggleMenu(isOpen);
    const e = {
      value: values,
      isOpen
    }
    if (isOpen) setTemp(values);
    if (!isOpen) setTemp([]);
    if (isOpen && typeof onShow === 'function') {onShow(e);}
    if (isOpen && typeof onOpen === 'function') {onShow(e);}
    if (!isOpen && JSON.stringify(values) !== JSON.stringify(temp) && typeof onClose === 'function') {onClose(e);}
    if (!isOpen && typeof onHide === 'function') {onHide(e)}
  }

  const handleReset = (e) => {
    setValues(defaultValue || []);
    if (typeof onReset === 'function') {return onReset({
      value: defaultValue || [],
      isOpen: false
    });}
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    setValues(value || initialValue || defaultValue || [])
  },[])
  
  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <Dropdown autoClose="outside" className={`${className}`} onToggle={handleToggle}>
      <div className={`${styles.container} ${showReset ? styles.withReset : ''} form-group cursor-pointer`}>
        {icon && <div className={`form-icon ${styles.icon}`}><i className={icon}></i></div>}
        <Dropdown.Toggle as={'div'} className={`form-control d-flex align-items-center w-100 text-gray-2 ${styles.input} ${isMenuOpen ? styles.active : ''}`} title={exibidos()}>
          <span>{prefix}{exibidos()}</span>
        </Dropdown.Toggle>
        {showReset && <button className={styles.reset} onClick={handleReset}><i className="fa fa-xmark"></i></button>}
      </div>
      <Dropdown.Menu className={styles.menu}>
        {showSearch && (<>
          <div className={`form-group ${styles.search}`}>
            <div className="form-icon"><i className="fa fa-search"></i></div>
            <input type="text" className={`form-control ${styles.input}`} value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Buscar" />
          </div>
        </>) }
        <div className={styles.items}>
          {options.filter((i) => i.label.includes(search) || i.value.includes(search)).map((item:ifcOptions, index) => (
            <Dropdown.Item key={index} as={'label'} className={'d-flex align-items-center'}> 
              <input type="checkbox" name={item.value} className={'me-2'} checked={values.indexOf(item.value) > -1} onChange={handleCheck}/>
              {item.label}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  </>)
}
  
export default SelectList;