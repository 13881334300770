import { InitialState } from "./types";

export const INITIAL_STATE:InitialState = {
  startDate           : undefined,
  endDate             : undefined,
  minDate             : undefined,
  maxDate             : undefined,
  selected            : undefined,
  excludeDates        : undefined,
  excludeDateIntervals: undefined,
  manual              : undefined,
  openToDate          : undefined,
  predefined          : false,
  isPredefined        : false,
  init                : false,
  custom              : false,
  temp                : {
    date  : undefined,
    custom: false,
    manual: undefined
  },
}
export const reducer = (state = INITIAL_STATE, action) => {
  const {context, data} = action;
  return {...state, [context]: data}
}