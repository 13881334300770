import React from 'react';
import LoginBody from '@Components/LoginBody';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';
import Page404 from "@Pages/Publicas/Page404";
import WebApi from '@Services/WebApi';
import Loading from '@Components/Loading';
import { format, parse, isBefore } from 'date-fns';

// https://clientes.fidelizii.com.br/whatsapp/resgate?t=6JxouEpzcUGVmLpPBTSL46cN7qVAO7FuhpK8D8hCdsH5YdH0Sv8kL0WBhgN91ku3YPkcxZU2W5SRiGX37rdZ5lkvCCtgI82O9wFh

// var ativacaoPremioCampanhaWhatsapp = function (token) {
//   token = token || "";
//   return $resource(FZ.API_URL + 'whatsapp/resgate?t=' + token, {});
// };

// sem token = 404
  
const WhatsappResgate = (props) => {
  let   [searchParams, setSearchParams] = useSearchParams();
  const TOKEN                           = searchParams.get('t');

  const [isLoading, setLoading] = React.useState(true);
  const [cliente, setCliente]   = React.useState(null);
  const [parceiro, setParceiro] = React.useState(null);
  const [premio, setPremio]     = React.useState(null);
  const [message, setMessage]   = React.useState(null);
  const [isValid, setValid]     = React.useState(false);


  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    WebApi.get('/clientes/whatsapp/resgate',{params: {t: TOKEN}}).then(({data}) => {
      setValid(true);
      setMessage(data.message);
      setCliente(data.cliente);
      setParceiro(data.parceiro);
      setPremio(data.premio);
    }).catch(err => {
      setValid(false);
      setMessage(err.data.errors?.[0] || 'Ocorreu um erro inesperado, tente novamente!');
    }).finally(() => setLoading(false));
  },[])
  
  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {!TOKEN && <Page404/>}
    {TOKEN && (<>
      <LoginBody>
        {isLoading && (<>
          <p className="text-purple-3 fs-16 fw-700 mb-0">Verificando e ativando o seu prêmio...</p>
          <p className="small text-gray-2">Por favor, não feche esta janela!</p>
          <hr className="dashed mb-4" />
          <Loading centered/>
        </>)}
        {!isLoading && (<>
          {!isValid && (<>
            <p className='text-purple-3 fs-16 fw-700 mb-0'>Oops! Não foi possível ativar seu prêmio... 😞</p>
            <p className="text-gray-2 fs-15">{message}</p>
          </>)}
          {isValid && (<>
            {(isBefore(new Date(), parse(premio.data_validade, 'yyyy-MM-dd HH:mm:ss', new Date())) && !premio.data_resgate) && (<>
              <h3 className='text-purple-3 fw-700 mb-3'>{message}!</h3>
              <p className="text-gray-2 fs-15">Não perca tempo, corra até <a className='text-purple fw-700' target="_blank" href={`https://fidelizi.com.br/${parceiro.slug}`}>{parceiro.nome}</a> para resgatar o seu prêmio ({premio.nome})!</p>
              <p className="text-gray-2 fs-15">Você tem até o dia <span className="text-gray-1 fw-500">{format(parse(premio.data_validade, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</span> para resgatar o seu prêmio, para mais informações <a className='text-purple fw-700' target="_blank" href={`https://fidelizi.com.br/${parceiro.slug}`}>clique aqui</a>.</p>
            </>)}
            {premio.data_resgate && (<>
              <p className='text-purple-3 fs-17 fw-700 mb-3'>Você já resgatou este prêmio!</p>
              <p className="text-gray-2 fs-15">Este prêmio foi resgatado em <a className='text-purple fw-700' target="_blank" href={`https://fidelizi.com.br/${parceiro.slug}`}>{parceiro.nome}</a> no dia <span className="text-gray-1 fw-500">{format(parse(premio.data_resgate, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</span>.</p>
              <p className="text-gray-2 fs-15"><a className='text-purple fw-700' target="_blank" href={`https://fidelizi.com.br/${parceiro.slug}`}>Clique aqui</a> para saber de mais prêmios esperando por você!</p>
            </>)}
            {(!isBefore(new Date(), parse(premio.data_validade, 'yyyy-MM-dd HH:mm:ss', new Date())) && !premio.data_resgate) && (<>
              <p className='text-purple-3 fs-17 fw-700 mb-3'>Oops! Parece que seu prêmio já expirou! 😞</p>
              <p className="text-gray-2 fs-15">A data de resgate do seu prêmio era até <span className="text-gray-1 fw-500">{format(parse(premio.data_validade, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</span>.</p>
              <p className="text-gray-2 fs-15">Mas não fique triste, acesse a página de <a className='text-purple fw-700' target="_blank" href={`https://fidelizi.com.br/${parceiro.slug}`}>{parceiro.nome}</a> para saber de mais prêmios esperando por você!</p>
            </>)}
            
          </>)}
          
          


          <hr className="dashed my-5" />
          <Link to="/" className="btn btn-border-purple-3">Ir Para Página Inicial</Link>
        </>)}
      </LoginBody>
    </>)}
  </>)
}
  
export default WhatsappResgate;