import React from "react";
import { format, parse } from "date-fns";
import { filter, find } from "lodash";
import { useAuth } from "@Contexts/Auth";
import TimelineEventos from "@Components/Cliente/TimelineEventos";

const Ofertas = props => {
  const { parceiro } = useAuth();

  const ofertas = filter(parceiro.ofertas, (item) => !item.oferta_indicacao);
  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {ofertas.length > 0 && (<>
      <div className="border-box mb-3">
        <p className="text-center">Confira abaixo as ofertas ativas e suas recompensas!</p>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Oferta</th>
              <th>Brinde</th>
              <th>Disponível Até</th>
            </tr>
          </thead>
          <tbody>
            {ofertas.map((item, key) =>{ 

              const premio = find(parceiro.brindes, {id_premio: item.id_premio});
              
              return (<React.Fragment key={key}>
              <tr>
                <td><i className="fa fa-ticket"></i></td>
                <td><a className="text-purple-3" href={`https://ofertas.fidelizii.com.br/${parceiro.slug}/${item.id_oferta}`} rel="noreferrer nofollow" target="_blank">{item.nome} <i class="fa fa-arrow-up-right-from-square"/></a></td>
                <td>{premio.nome}</td>
                <td>
                  {new Date(item.data_final).getFullYear() === 2999 && <>-</>}
                  {new Date(item.data_final).getFullYear() !== 2999 && <>{format(parse(item.data_final, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</>}
                </td>
              </tr>
            </React.Fragment>)})}
          </tbody>
        </table>
      </div>
    </>)}
    <div className="border-box" style={{minHeight: "400px"}}>
      <div className="d-block d-md-flex align-items-center mb-5">
        <h3 className="text-purple-2 fw-bold text-left m-0">Histórico</h3>
      </div>
      <TimelineEventos atividades="ofertas" eventosArray={['ofertas_ativadas']} />
    </div>
  </>);
};

export default Ofertas;
