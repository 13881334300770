import { useState } from "react";
import { generatePath, Link } from "react-router-dom";
import _ from "lodash";
import styles from "./styles.module.scss";
import { useAuth } from "@Contexts/Auth";
import FzAlert from "@Components/FzAlert";
import ModalIndica from "@Components/ModalIndica";

const Estabelecimentos = () => {
  const { 
    user,
    rede
  } = useAuth();

  const [search, setSearch] = useState('');


  return (
    <>
    {/* <FzAlert className={styles.pesquisaAlert}>
      <div className={"d-flex flex-column justify-content-center align-items-center p-2"}>
        <h4 className="text-white mb-2"> Participe da nossa pesquisa sobre indicação </h4>
        <a target="_blank" href="https://docs.google.com/forms/d/1mKseH4XurE05zxgafpI25Pz015zWYIRuuCr9pzka5xU/viewform?edit_requested=true"
        style={{color: '#FED901'}}> <h5>Clique aqui e ajude-nos a melhorar o Fidelizi para você! É rapidinho.</h5></a>
      </div>
    </FzAlert> */}
    <div className="container">
      <div className="row pb-5">
        <div className="col-md-12">
          <div className="containerChoose">
            <h2 className="text-center my-5 text-gray-1 fs-30">
              Olá <span className="text-purple-3">{user.nome_curto}</span>! Escolha um estabelecimento para continuar:
            </h2>

            <hr className="dashed" />
            {rede.length === 0 && (<>
              <p className={styles["textWelcome"]}>Você ainda não participa do programa de fidelidade de nenhum estabelecimento 😔</p>
              <div className="d-flex justify-content-center"><img src={'/assets/img/descobrir.png'} alt="descubra estabelecimentos" className="imgNewClient" /></div>
              <p className={styles["textWelcome"]}>Descubra os estabelecimentos com Fidelizi na sua cidade e comece a ganhar pontos!</p>
              <a href="https://fidelizii.com.br/clientes/" className="btn btn-gradient-purple">DESCOBRIR</a>
            </>)}
            {rede.length > 0 && (<>
              <div className={`form-group input-group mb-4 ${styles.search}`}>
                <span className="input-group-text"><i className="fa fa-search"></i></span>
                <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder="Buscar Estabelecimento..." className="form-control" />
              </div>
              <ul className={styles.lista}>
                {_.filter(_.orderBy(rede, ['nome','asc']), (item, key) => item.nome.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase())).map((estabelecimento) => (
                  <li key={estabelecimento.id_parceiro}>
                    <Link className={styles.parceiro} to={generatePath(`/dashboard/:idParceiro`,{idParceiro: estabelecimento.id_parceiro || ''})}>
                      <div className={styles.logo}>
                        <img className={styles.full} alt={estabelecimento.nome} src={estabelecimento.link_logotipo_interno}/>
                      </div>
                      <h3 className={styles.nome}>{estabelecimento.nome}</h3>
                    </Link>
                  </li>
                ))}
              </ul>
            </>)}
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-4">
        <div className="col-md-12 text-center">
          <div className={styles.indica}>
            <p className="fs-16 text-gray-2">Gostaria de ver o Fidelizi em outro estabelecimento?</p>
            <ModalIndica>
              <button className="btn btn-bg-purple-3 text-white py-4 px-5 w-100 fs-16">
                <i className="fas fa-share-nodes me-3 fs-24 fw-600"></i> Indique o Fidelizi!
              </button>
            </ModalIndica>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Estabelecimentos;
