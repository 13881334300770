import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import WebApi from "@Services/WebApi";
import styles from "../styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "@Components/Loading";
import Swal from "sweetalert2";
import ForcaSenha from "@Components/ForcaSenha";

import { validarPassword } from "@Services/Helpers";


import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { LOGIN_TOKEN } from "@Contexts/Auth";


function ModalToken({ show, token: t, setshow }) {
  //Carregamento
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [isValidating, setValidating] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [token, setToken] = useState(searchParams.get('t'));
  const [recaptcha, setRecaptcha] = useState("");

  const strength = validarPassword(password, true);
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(token);
  }, [token])

  function handleAlterar() {
    setLoading(true);
    WebApi.post(`/clientes/recuperar-senha/token`, {
      custom_token: token,
      password: password,
      password_confirmation: confirmPass,
      recaptcha
    }).then(res => {
      Swal.fire('Oba!', 'Senha alterada com sucesso! Você será redirecionado ao seu painel.', 'success')
      localStorage.setItem(LOGIN_TOKEN, res.data.access_token);
      navigate("/dashboard");
    }).catch(err => {
      console.log(err);
      return Swal.fire("Oops!", err?.data?.errors?.[0] || 'Ocorreu um erro desconhecido, tente novamente.', 'error');
    }).finally(() => setLoading(false));
  }

  React.useEffect(() => {
    if (!token) return;
    WebApi.post(`/clientes/recuperar-senha/validar`, { custom_token: token }).then(res => {
    }).catch(err => {
      setshow(false);
      return Swal.fire("Oops!", err?.data?.errors?.[0] || 'Ocorreu um erro desconhecido, tente novamente.', 'error');
    }).finally(() => {
      setValidating(false);
      setSearchParams('')
    });
  }, [])

  return (
    <>
      <Modal show={show} centered={true} backdrop={'static'} onHide={() => setshow(false)} className="modalToken">
        <Modal.Header closeButton>
          <Modal.Title>Cadastre a sua senha de acesso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isValidating && (<Loading centered className="mxy-5">Checando Token...</Loading>)}
          {!isValidating && (<>
            <div className={styles.inputsWithCaptcha}>
              <div className="form-group">
                <label>Digite sua senha</label>
                <input className="form-control mb-1" type="password" placeholder="Coloque a senha aqui" onChange={(e) => setPassword(e.target.value)} />
              </div>

              <div className="form-group">
                <label>Confirme sua senha</label>
                <input className="form-control" type="password" placeholder="Confirme a senha aqui" onChange={(e) => setConfirmPass(e.target.value)} />
              </div>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                name="recaptcha"
                onChange={(e) => setRecaptcha(e)}
                className={`mt-4 mb-4 ${styles.recaptcha}`}
              />

            </div>
          </>)}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block w-100 pe-4">
              <ForcaSenha level={strength.level} />
              <p className="fs-10 fw-500 mb-0">{strength.message}</p>
            </div>
            <button disabled={password.length < 8 || confirmPass.length < 8 || password !== confirmPass || recaptcha === ''} className="btn btn-gradient-purple ms-auto" onClick={handleAlterar}>
              {!isLoading && <span>Cadastrar Senha<i className="fa fa-angle-right"></i></span>}
              {isLoading && <Loading color="#fff" size={14} centered />}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalToken;
