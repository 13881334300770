import {parseISO, format, differenceInCalendarDays, parse, isValid} from 'date-fns';



//////////////////////////////////////////////////////////////////////////////////////
// Validators
//////////////////////////////////////////////////////////////////////////////////////
export const validarEmail = (email, details) => {
  if (!email) return false;
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValid = re.test(email);
  if (!details) return isValid;

  return {
    success: isValid,
    message: isValid 
    ? 'E-Mail Válido' 
    : 'E-Mail Inválido'
  }
}
export const validarPassword = (password, details) => {
  var regex    = [];
  let level    = 0;
  let strength = '';
  regex.push("[a-z]");  //For Lowercase Alphabet
  regex.push("[A-Z]"); //For Uppercase Alphabet
  regex.push("[0-9]");  //For Numeric Digits
  regex.push("[$@$!%*#?&]"); //For Special Characters

  password = password.replace(/\s/g, '');

  // Se o tamanho for inferior a 8 caracteres
  if (password.length < 8) {
    if (!details) return false;
    return {
      success: false,
      message: 'A senha deve possuir pelo menos 8 caracteres.',
      level  : 0,
    }
  }

  // Verifica se a senha contem cada regra especial (até 4 pontos)
  for (var i = 0; i < regex.length; i++) {
      if(new RegExp (regex[i]).test(password)) {
          level++;
      }
  }

  // Verifica se a senha tem um tamanho decente (desconta até 3 pontos)
  if (password.length <= 7) {level--;}
  if (password.length <= 12) {level--;}
  if (password.length <= 15) {level--;}

  // Se a senha do cara for ruim, mesmo assim tem que ser fraca
  if (level < 1) {level = 1;}

  if (!details) return true;

  if (level === 1)
    strength = 'Senha Fraca.';
  if (level === 2)
    strength = 'Senha Boa.';
  if (level === 3)
    strength = 'Senha Forte.';
  if (level === 4)
    strength = 'Senha Perfeita.';

    
  return {
    success: true,
    message: strength,
    level: level,
  }
}
export const validarCPF = (cpf) => {
  if (!cpf) return false;
  let sum = 0;
  let rest = 0;

  if (!cpf) return;

  let cleanCPF = cpf.replace(/\D/g, '');

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cleanCPF.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(cleanCPF.substring(10, 11))) return false;

    return true;
}
export const validarNomeCompleto = (nome) => {
  if (!nome) return false;
  return nome.split(' ').length > 1;
}
export const validarCelular = (celular) => {
  if (!celular) return false;
  const regEx       = /^\+[1-9]\d{12,14}$/;
  const clearNumber = celular.replace(/\D/g, '');
  return regEx.test('+' + clearNumber);
}
export const validarData = (data) => {
  if (!data) return false;
  return isValid(parse(data, 'dd/MM/yyyy', new Date())) || isValid(parse(data, 'yyyy-MM-dd', new Date()));
}
export const convertStringToDate = (value) => {
  if (typeof value === 'string') {
    let parsed = [
      parse(value, "yyyy-MM-dd HH:mm:ss", new Date()),
      parse(value, "yyyy-MM-dd", new Date()),
      parse(value, "dd/MM/yyyy", new Date()),
      parse(value, "dd/MM/yyyy HH:mm:ss", new Date()),
    ]
    if (isValid(parsed[0])) { return parsed[0] }
    if (isValid(parsed[1])) { return parsed[1] }
    if (isValid(parsed[2])) { return parsed[2] }
    if (isValid(parsed[3])) { return parsed[3] }
  }

  return undefined;
}
export const validarSexo = (sexo) => {
  if (!sexo) return false;
  return ['M', 'm', 'F', 'f', 'o', 'O', 'na', 'NA'].indexOf(sexo) > -1;
}

//////////////////////////////////////////////////////////////////////////////////////
// Formatters
//////////////////////////////////////////////////////////////////////////////////////
export const Plano = (slug) => {
  const plano = {
    'starter'    : "STARTER",
    'pro'        : "PLUS",
    'smart'      : "SMART",
    'premium'    : "PREMIUM",
    'advanced'   : "ADVANCED",
    'basic'      : "BASIC",
    'pro-2'      : "PRO",
    'elite'      : "ELITE",
    'essential'  : "ESSENTIAL",
    'cielo-lite' : "CIELO LITE",
    'cielo-pro'  : "CIELO PRO",
    'cielo-elite': "CIELO ELITE",
    'prime'      : "PRIME",
  }

  return plano[slug];
}
export const formatDate = (date, formato) => {
  return format(parseISO(date), formato || 'dd/MM/yyyy');
}
export const DateFromString = (value) => {
  if (typeof value?.getMonth === 'function') {
    return value
  }
  if (typeof value === 'string') {
    let parsed = [
      parse(value, "yyyy-MM-dd HH:mm:ss", new Date()),
      parse(value, "yyyy-MM-dd", new Date()),
      parse(value, "dd/MM/yyyy", new Date()),
      parse(value, "dd/MM/yyyy HH:mm:ss", new Date()),
    ]
    if (isValid(parsed[0])) { return parsed[0] }
    if (isValid(parsed[1])) { return parsed[1] }
    if (isValid(parsed[2])) { return parsed[2] }
    if (isValid(parsed[3])) { return parsed[3] }
  }

  return undefined;
}
export const expiraEm = (date) => {
  let diff = differenceInCalendarDays( parseISO(date), new Date() );
  if (diff > -1) {
    if (diff === 0) return 'Hoje';
    if (diff === 1) return 'Amanha';
    if (diff > 1) return `${diff} dias`;
  } else {
    return 'Expirado';
  }
}
export const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(value));
}


//////////////////////////////////////////////////////////////////////////////////////
// Misc
//////////////////////////////////////////////////////////////////////////////////////
export const calcularVariacaoPercentual = function (valorAtual, valorAnterior) {
  let data = {};

  if (valorAnterior > 0) {
    data.valor = (((valorAtual / valorAnterior) - 1) * 100).toFixed(1);
    data.variacao = (data.valor >= 0) ? 'up' : 'down';
  } else if (valorAtual === 0 && valorAnterior === 0) {
    data.valor = 0;
    data.variacao = 'up';
  } else {
    data.valor = 100;
    data.variacao = 'up';
  }

  return data;
}
export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n) && Math.sign(n) >= 0;
}
export const getPosition = (subString, string, index) => {
  return string.split(subString, index).join(subString).length;
}
/**
 * @deprecated
 */
export const isValidDate = (dateValue) => {
  if (!dateValue) return false;

  let date = dateValue;

  if (typeof dateValue === 'string') {
    date = new Date(dateValue)
  }

  return (date instanceof Date && !isNaN(Number(date)));
}

export const calcularCashback = function(valor, cartelaModelo) {
  let percentual = parseFloat(cartelaModelo.percentual_cashback) / 100
  let min = parseFloat(cartelaModelo.valor_minimo_cashback);
  let max = parseFloat(cartelaModelo.valor_maximo_cashback_ganho);
  let val = parseFloat(valor);
  let calculado = val * percentual
  if (val < min) return 0;
  if (max > 0 && calculado > max) return max;
  return calculado;
}