import React, { useState } from "react";

import { useAuth } from "@Contexts/Auth";
import ResumoPrograma from "@Components/ResumoPrograma";
import Tablet from "@Components/Tablet";

import styles from "./styles.module.scss";


const Detalhes = () => {
  const { parceiro } = useAuth();
 

  return (<>
    <div className="d-block d-lg-flex">
      <div className="me-lg-5">
        <Tablet
          className={`${styles.tablet} mx-auto mb-4 mb-lg-0`}
          parceiroNome={parceiro.nome}
          corBarraSuperior={parceiro.identidade.cor_barra_superior}
          corTexto={parceiro.identidade.cor_texto}
          fundoTelaInicial={parceiro.identidade.tela_inicial}
          programaChamada={parceiro.programa.chamada}
          programaDescricao={parceiro.programa.descricao}
          programaObservacao={parceiro.programa.observacao}
          />
      </div>
      <div className={`mb-4 ${styles.parceiroResumo}`}>
        <h2 className={styles.titulos}>{parceiro.identidade.programa || 'Programa de Fidelidade'}</h2> 
        <ResumoPrograma programa={parceiro.programa} />
        
        { parceiro.descricao && (<>
          <hr className="mx-5" />
          <div className={styles.parceiroDescricao} dangerouslySetInnerHTML={{__html: parceiro.descricao}}></div>
        </>)}
      </div>
    </div>
  </>);
};

export default Detalhes;
