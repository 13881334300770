import axios from "axios";
import { LOGIN_TOKEN } from "@Contexts/Auth";
import { toast } from "react-toastify";

const WebApi = axios.create({
  baseURL: process.env.REACT_APP_URL_WEBAPI,
});

/**
 * Interceptor for every request
 */
const RequestInterceptor = async (config) => {
  const token = localStorage.getItem(LOGIN_TOKEN);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};


/*** Interceptor for every success */
const ResponseSuccessInterceptor = (response) => {
  return response;
};

/*** Interceptor for every error */
const ResponseErrorInterceptor = (response) => {
  
  // Bad Request
  if (response.response?.status === 400) {
      console.warn("Bad Request");
  }

  // Unauthorized
  if (response.response?.status === 401) {
      console.warn("Unauthorized");
  }

  // Forbidden
  if (response.response?.status === 403) {
      // const pathname = window.location.pathname.split('/');
      // window.location.href = '/dashboard/' + parseInt(pathname[2]) + '/sem-permissao';
      // return <Navigate to={'/dashboard/' + parseInt(pathname[2]) + '/sem-permissao'}/>
  }

  // Internal Server Error
  if (response.response?.status === 500) {
      toast.error('Ocorreu um erro desconhecido, entre em contato com o suporte!', {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastStyle: { backgroundColor: "crimson" }
      });
      console.warn("Internal Server Error");
  }

  return Promise.reject(response.response);
};

WebApi.interceptors.request.use(RequestInterceptor);
WebApi.interceptors.response.use(
  ResponseSuccessInterceptor,
  ResponseErrorInterceptor
);

export default WebApi;
