import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import WebApi from "@Services/WebApi";

import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import ReCAPTCHA from "react-google-recaptcha";
import ModalPassword from "./ModalEsqueciMinhaSenha";
import ModalToken from "./ModalNovaSenha";
import SwalLoading from "@Components/SwalLoading";
import LoginBody from "@Components/LoginBody";

import { useAuth } from "@Contexts/Auth";

function LoginPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [cpf, setCPF] = useState(sessionStorage.getItem("cpf") || "");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [cpfCheck, setCpfCheck] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [step, setStep] = useState("checkCPF");

  const [showModalPassword, setShowModalPassword] = useState(false);  //Esqueceu Senha
  const [token, setToken] = useState();       //Email Validação
  const [showToken, setShowToken] = useState(false);  //Modal de Cadastro

  function openModalPassword() {
    setShowModalPassword(true);
  }

  const {
    Login,
    isAuthenticated
  } = useAuth();


  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleVerifyCPF = (e) => {
    e.preventDefault();
    SwalLoading('Validando CPF...', '');
    WebApi.post("/clientes/auth/verificacao", { cpf, recaptcha }).then(res => {
      setName(res.data.cliente.nome);
      setCpfCheck(res.data.cliente.cpf);
      sessionStorage.setItem("cpf", cpf);
      setStep('checkAction');
      Swal.close();
    }).catch(err => {
      if (err?.status === 401) {
        return Swal.fire("Validação da conta pendente", err.data.errors[0], "warning");
      }
      if (err?.status === 403) {
        sessionStorage.setItem("cpf", cpf);
        Swal.close();
        return navigate("/cadastro");
      }
      return Swal.fire("Oops!", err?.data?.errors?.[0] || 'Ocorreu um erro inesperado!', 'error');
    });
  };

  const handleCaptcha = (e) => {
    setRecaptcha(e);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    SwalLoading('Efetuando Login...', '');
    Login({ cpf, password, recaptcha }).then(res => {
      navigate("/dashboard");
      Swal.close();
    }).catch(err => {
      console.log(err);
      Swal.fire('Oops', err, 'error');
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////
  // Components
  //////////////////////////////////////////////////////////////////////////////////////
  const checkCPF = (<>
    <form className={styles["formContainer"]} onSubmit={handleVerifyCPF}>
      <label>Para continuar, digite o seu CPF</label>
      <div className={styles.inputsWithCaptcha}>
        <NumberFormat onChange={(e) => setCPF(e.target.value)} className="form-control text-center font-rounded fw-500" format="###.###.###-##" placeholder="___.___.___-__" mask={['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_']} />
        <div className="d-flex">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            name="recaptcha"
            onChange={handleCaptcha}
            className={`mt-4 mx-auto ${styles.recaptcha}`}
          />
        </div>
        <button className="btn btn-gradient-purple fw-bold fs-16 px-5 py-3 w-100 mt-4" disabled={recaptcha === ''} type="submit">
          <span>Continuar <i className="fa fa-chevron-right iconContinuar"></i></span>
        </button>
      </div>
    </form>
  </>);
  const checkAction = (<>
    <p><b>O que você gostaria de fazer?</b></p>
    <div className={styles.option}>
      <label>Gostaria de resgatar um código de pontos!</label>
      <Link to="/resgatar-ponto">
        <button className="btn btn-bg-purple-3 px-5 py-3 w-100 text-white ">RESGATAR PONTO</button>
      </Link>
    </div>
    <div className={styles.option}>
      <label>Quero acessar minha área do cliente!</label>
      <button className="btn btn-border-purple-3 px-5 py-3 w-100" onClick={() => setStep('checkPassword')}>
        ACESSAR ÁREA DE CLIENTES
      </button>
    </div>
  </>);
  const checkPassword = (<>
    <form className={styles["formContainer"]} onSubmit={handleLogin}>
      <p className={styles["formNome"]}>Olá {name}</p>
      <div className={styles.inputsWithCaptcha}>
        <div className="form-group">
          <input placeholder={cpfCheck} type="text" disabled className="form-control text-center" />
        </div>
        <div className="form-group">
          <input placeholder="Digite sua senha" type="password" className="form-control text-center" onChange={(e) => setPassword(e.target.value)} />
        </div>
        <a className="fs-13 px-5 w-100 text-blue-6" onClick={openModalPassword} type="button">
          Esqueceu a senha ? Clique aqui
        </a>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          name="recaptcha"
          onChange={handleCaptcha}
          className={`mt-4 mb-4 ${styles.recaptcha}`}
        />
        <button className="btn btn-gradient-purple fs-16 px-5 py-3 w-100 mb-2" disabled={recaptcha === ''} type="submit">
          Continuar
          <i className="fa fa-chevron-right iconContinuar ms-2"></i>
        </button>
      </div>
    </form>
    <div className={styles.inputsWithCaptcha}>
      <div className={styles["contentMensagem"]}>
        <hr className="dashed" />
        <p className="text-center mt-3">
          Mudei de ideia! Gostaria de <a className="text-purple-3" href="/resgatar-ponto">resgatar pontos</a> ao invés de acessar a área dos clientes!
        </p>
      </div>
    </div>
  </>);

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/dashboard");
    }
    if (searchParams.has('t')) {
      setToken(searchParams.get("t"));
      setShowToken(true);
    }
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <ModalPassword show={showModalPassword} setshow={setShowModalPassword} CPF={cpf} />
    <ModalToken show={showToken} setshow={setShowToken} token={token} />
    <LoginBody>
      <p className="text-purple-3 fs-13 fw-700">Acompanhe suas atividades, confira seus beneficios e fique por dentro de tudo nos programas de fidelidade que você participa.</p>
      <hr className="dashed mb-4" />

      {step === 'checkCPF' && checkCPF}
      {step === 'checkAction' && checkAction}
      {step === 'checkPassword' && checkPassword}

    </LoginBody>

  </>);
}

export default LoginPage;
