import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {format, subDays} from "date-fns";
import WebApi from "@Services/WebApi";
import {useAuth} from "@Contexts/Auth";
import PageLoading from "@Components/Loading";
import SelectList from "@Components/SelectList";
import Pagination from '@Components/Pagination';
import FzDatePicker from "@Components/FzDatePicker";
import Item from "./Item";
import styles from "./styles.module.scss";

interface TimelineEventosProps {
    displayFilters: boolean;
    eventosArray?: Array<string>;
    atividades: string;
}

const TimelineEventos = ({ displayFilters = false, eventosArray = [], atividades }: TimelineEventosProps) => {
    const options = [
        {
            label: 'Compras',
            value: 'compras',
        },
        {
            label: 'Compras canceladas',
            value: 'compras_canceladas',
        },
        {
            label: 'Prêmios da fidelidade resgatados',
            value: 'fidelidade_resgatados',
        },
        {
            label: 'Brindes da roleta conquistados',
            value: 'roleta_conquistados',
        },
        {
            label: 'Brindes da roleta resgatados',
            value: 'roleta_resgatados',
        },
        {
            label: 'Brindes da roleta expirados',
            value: 'roleta_expirados',
        },
        {
            label: 'Prêmios Surpresa conquistados',
            value: 'surpresa_conquistados',
        },
        {
            label: 'Prêmios Surpresa resgatados',
            value: 'surpresa_resgatados',
        },
        {
            label: 'Prêmios Surpresa expirados',
            value: 'surpresa_expirados',
        },
        {
            label: 'Prêmios de campanha conquistados',
            value: 'campanha_conquistados',
        },
        {
            label: 'Prêmios de campanha resgatados',
            value: 'campanha_resgatados',
        },
        {
            label: 'Prêmios de campanha expirados',
            value: 'campanha_expirados',
        },
        {
            label: 'Ofertas ativadas',
            value: 'ofertas_ativadas',
        },
        {
            label: 'Pesquisas respondidas',
            value: 'pesquisas_respondidas',
        },
    ];

    const DEFAULT_EVENTOS = [
        'compras', 
        'compras_canceladas', 
        'fidelidade_resgatados', 
        'roleta_conquistados', 
        'roleta_resgatados', 
        'roleta_expirados',
        'campanha_conquistados',
        'campanha_resgatados',
        'campanha_expirados',
    ];

    const [eventos, setEventos] = useState(eventosArray?.length === 0 ? DEFAULT_EVENTOS : eventosArray);

    const { parceiro, user } = useAuth();

    const [isLoading, setIsLoading] = useState(true);

    const DEFAULT_FILTERS = {
        data_inicio: format(subDays(new Date(), 60), 'yyyy-MM-dd'),
        data_fim: format(new Date(), 'yyyy-MM-dd'),
    };

    const [periodo, setPeriodo] = useState({...DEFAULT_FILTERS});

    const [resultados, setResultados] = useState({
        search: '',
        page: 1,
        last_page: 1,
        per_page: 15,
        current_page: 1,
        first_page_url: "",
        from: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        prev_page_url: "",
        to: 0,
        total: 0,
        data: []
    });

    const handleDateChange = (date) => {
        setPeriodo((prev) => ({data_inicio: date.dtStart, data_fim: date.dtEnd}));
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            let url = `/clientes/estabelecimentos/${parceiro.id_parceiro}/historico`;

            const response = await WebApi.get(url, {
                params: {
                    eventos: eventos,
                    page: resultados.current_page,
                    atividades: atividades,
                    ...periodo
                }
            });

            setResultados(response.data.interacoes);
            setIsLoading(false);
        } catch (err: any) {
            toast.error(err.data.errors[0], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleSubmit();
    }, [resultados.current_page]);

    return <>

        <div className="container mt-3">
            {
                displayFilters &&
                    (
                        <>
                            <SelectList
                                options={options}
                                prefix="Eventos: "
                                icon={"fa fa-tag"}
                                // defaultValue={['compras', 'cadastros', 'nps']}
                                value={eventos}
                                onClose={(e) => setEventos(e.value)}
                                onReset={(e) => setEventos(e.value)}
                                className={styles.status}
                                showReset
                            />
                    </>
                )
            }

                            <div
                                className="d-block d-md-flex text-center text-md-left align-items-center mb-4 mt-3 gap-3">
                                <div className="flex-fill align-items-center">
                                    <FzDatePicker
                                        selectsRange={true}
                                        predefinedDates={true}
                                        // onChange={(e,z) => handleDateChange(e.date)}
                                        className={styles.datepicker}
                                        startDate={DEFAULT_FILTERS.data_inicio}
                                        endDate={DEFAULT_FILTERS.data_fim}
                                        minDate={user.date_cadastro}
                                        maxDate={new Date()}
                                        onClose={(e, a) => handleDateChange(a.date)}
                                        onSelect={(e, a) => handleDateChange(a.date)}
                                    />
                                </div>

                                <div className="">
                                    <button className="btn btn-bg-purple text-white"
                                            type="submit" onClick={handleSubmit}><i
                                        className="fa fa-search"></i> Filtrar
                                    </button>
                                </div>
                            </div>


            {isLoading && (<PageLoading centered size={80} />)}

            {
                !isLoading && (
                    <>
                        <div
                            className="flex-fill align-items-center text-gray-2 p-1 mt-2"
                        >
                            Listando {
                            resultados.per_page > resultados.total ? resultados.total : resultados.per_page
                        } evento(s) de um total de {resultados.total}.
                        </div>

                        <Pagination 
                            onPaginate={(value) => setResultados((prev) => ({...prev, current_page: value}))}
                            paginateData={resultados}
                        >
                            <div className="">
                                {resultados.data.map((item, index, eventos) => (
                                    <Item key={index} index={index} evento={item} eventos={eventos} idParceiro={parceiro.id_parceiro}/>
                                ))}

                                {resultados.data.length === 0 && (
                                    <div className={styles.empty}> Nenhuma transação registrada nesse período. </div>
                                )}
                            </div>
                        </Pagination>
                    </>
                )
            }
        </div>
    </>
}

export default TimelineEventos;
