import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({prefix,title,description,children}) => {
  return (
    <>
      <Helmet>
          <title>{`${(prefix) ? prefix : 'Área do Cliente'} ${((title) ? ` - ${title}` : '')}`}</title>
          <meta name="description" content={description || 'Consulte seu saldo nos estabelecimentos em que participa do programa de fidelidade no Fidelizi e indique seus amigos para ganhar recompensas.'}/>
          {children}
      </Helmet>
    </>
  )
}

export default React.memo(SEO);