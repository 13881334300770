import React from "react";
import { useAuth } from "@Contexts/Auth";
import styles from "./styles.module.scss";
import TimelineEventos from "@Components/Cliente/TimelineEventos";

const Premios = props => {
  const { parceiro }                = useAuth();

  /////////////////////////////////////////////////////////////////////////////////////
  // Components
  /////////////////////////////////////////////////////////////////////////////////////
  const Progress = ({premio}) => {
    let percent = ((parceiro.cartela_atual?.saldo / premio.preco_odometro) * 100).toFixed(0);
    percent = (percent > 100) ? 100 : percent;

    return (<>
      <div className={styles.progressbar}>
        <div className={styles.title}>
            
          <div className={styles.name}>
            {premio.nome} -
            <span className={styles.price}> {premio.preco_odometro} {parceiro.nome_ponto ? parceiro.nome_ponto:'pontos'}</span>
          </div>
          {percent < 100 && <div className={styles.status}>Faltam {premio.preco_odometro - parceiro.cartela_atual?.saldo} {parceiro.nome_ponto ? parceiro.nome_ponto:'pontos'}</div>}
          {percent === 100 && <div className={styles.status}>Resgate no estabelecimento</div>}
        </div>

        <div className={styles.bar}>
          <div
            className={`${styles.progress} ${percent < 100 ? styles.incomplete : styles.complete}`}
            role="progressbar"
            aria-valuenow={parceiro.cartela_atual?.saldo}
            aria-valuemin="0"
            aria-valuemax={premio.preco_odometro}
            style={{ width: percent + "%" }}
          >
            <label>{percent}%</label>
          </div>
        </div>
      </div>
    </>)
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {parceiro.premios.length > 0 && (<>
      <div className="border-box mb-3">
        <p className="text-center">Confira abaixo os prêmios que você tem disponível para resgate no estabelecimento!</p>
        {parceiro.premios.map((premio, key) => (<React.Fragment key={key}>
          <Progress premio={premio}/>
        </React.Fragment>))}
      </div>
    </>)}
    <div className="border-box" style={{minHeight: "400px"}}>
      <div className="d-block d-md-flex align-items-center mb-5">
        <h3 className="text-purple-2 fw-bold text-left m-0">Histórico</h3>
      </div>
      <TimelineEventos atividades="premios" eventosArray={['fidelidade_resgatados']} />
    </div>

  </>);
};

export default Premios;
