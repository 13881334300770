import React from "react";
import { useAuth } from "@Contexts/Auth";
import CardSaldo from "@Components/CardSaldo";
import ExtratoPontos from "@Components/Cliente/ExtratoPontos";



const Pontos = props => {
  const { parceiro }                = useAuth();

  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <CardSaldo tipo={parceiro.cartela_atual?.modelo?.tipo} 
      saldoAtual={parceiro.cartela_atual?.saldo}
      saldoCarencia={parceiro.cartela_atual?.saldo_carencia}
      validadePontos={parceiro.cartela_atual?.proxima_validade_pontos} 
      validadeData={parceiro.cartela_atual?.proxima_validade_data} 
      nomePonto={parceiro.identidade?.pontos} 
    />
    <div className="border-box mt-3" style={{minHeight: "400px"}}>
      <div className="d-block d-md-flex align-items-center mb-4">
        <h3 className="text-purple-2 fw-bold text-left m-0">Extrato</h3>
      </div>
      <ExtratoPontos maxHeight={'600px'} />
    </div>
  </>);
};

export default Pontos;
