import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { subDays, subMonths, subYears, addDays, addMonths, addYears, startOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear, format, isAfter, isBefore, isValid, parse, subHours } from 'date-fns';
import styles from './styles.module.scss';
import { ComponentProps } from "./util/types";
import { INITIAL_STATE, reducer } from './util/reducer';
import { Dropdown } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

// v2.0
const FzDatePicker = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  selected,
  openToDate,
  excludeDates,
  excludeDateIntervals,

  dateFormat,
  selectsRange = false,
  predefinedDates,
  editable = true,

  shouldCloseOnSelect = false,
  showYearPicker,
  showMonthYearPicker,

  maxSelectDays,
  maxSelectMonths,
  maxSelectYears,

  filterTime,
  timeIntervals,
  showTimeSelect,

  onChange,
  onOpen,
  onClose,
  onClick,
  onSelect,

  position,
  inline = false,

  className,
  children,
  disabled,
  ...props
}: ComponentProps) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  function placeholderFormat() {
    if (dateFormat) {
      if (typeof dateFormat === 'function') return dateFormat(state.selected);
      return dateFormat;
    };
    if (showTimeSelect) return "d MMM/yyyy, HH'h'mm";
    if (showMonthYearPicker) return "MMM/yyyy";
    if (showYearPicker) return "yyyy";
    return "d MMM/yyyy";
  }
  /**
   * Trata as datas para retorno customizado, pode adicionar os formatos que forem necessários
   * @param value 
   * @returns 
   */
  const formatReturn = (value: any = false) => {
    const date: any = (value) ? value : ((selectsRange) ? [state.startDate, state.endDate] : state.startDate);
    return {
      string: (selectsRange)
        ? `${format(date[0], `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`)};${(!date[1]) ? undefined : format(date[1], `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`)}`
        : format(date, `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`),


      date: (selectsRange) ? {
        dtStart: format(date[0], `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`),
        dtEnd: (!date[1]) ? undefined : format(date[1], `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`),
      } : {
        dtStart: format(date, `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`),
        dtEnd: format(date, `yyyy-MM-dd${showTimeSelect ? ' HH:mm:ss' : ''}`),
      }
    }

  }

  const convertStringToDate = (value) => {
    if (typeof value?.getMonth === 'function') {
      return value
    }
    if (typeof value === 'string') {
      let parsed = [
        parse(value, "yyyy-MM-dd HH:mm:ss", new Date()),
        parse(value, "yyyy-MM-dd", new Date()),
        parse(value, "dd/MM/yyyy", new Date()),
        parse(value, "dd/MM/yyyy HH:mm:ss", new Date()),
      ]
      if (isValid(parsed[0])) { return parsed[0] }
      if (isValid(parsed[1])) { return parsed[1] }
      if (isValid(parsed[2])) { return parsed[2] }
      if (isValid(parsed[3])) { return parsed[3] }
    }

    return undefined;
  }

  /**
   * Verifica se a data informada (value) está entre o min e o max permitido
   * @param value
   * @returns 
   */
  const setDate = (value, ignoreMinMax = false) => {
    if (!value) return null;
    let min = state.minDate || $initialMinDate;
    let max = state.maxDate || $initialMaxDate;
    let val = value;
    if (min && isBefore(value, min) && !ignoreMinMax) val = min;
    if (max && isAfter(value, max) && !ignoreMinMax) val = max;

    if (!showTimeSelect && val) {
      val = startOfDay(val);
    }
    return val;
  }

  /**
   * Define os selected do calendário
   * @param value 
   */
  const setSelected = (value) => {
    if (!value) return;
    setState('startDate', (selectsRange) ? setDate(value[0]) : setDate(value));
    setState('endDate', (selectsRange) ? setDate(value[1]) : setDate(value));
    setState('selected', (selectsRange) ? [setDate(value[0]), setDate(value[1])] : setDate(value));
    setState('openToDate', (selectsRange) ? setDate(value[1]) : setDate(value));
  }

  /**
   * Reseta todos valores para o valor salvo quando o calendário foi aberto
   */
  const restoreDates = () => {
    if (selectsRange) {
      setState('startDate', state.temp.date[0]);
      setState('endDate', state.temp.date[1]);
      setState('selected', state.temp.date);
    } else {
      setState('startDate', state.temp.date);
      setState('endDate', state.temp.date);
      setState('selected', state.temp.date);
    }

    setState('custom', state.temp.custom);

  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Init
  //////////////////////////////////////////////////////////////////////////////////////
  // Locale
  registerLocale("ptBR", ptBR);
  // Context
  const [isDirty, setDirty]     = React.useState<boolean>(false);
  const [interval, setInterval] = React.useState<any>(undefined);
  const [state, dispatch]       = React.useReducer(reducer, INITIAL_STATE);
  const setState                = (key, value) => dispatch({ context: key, data: value });
  // References
  const calendarRef = React.useRef<any>();
  const now = startOfDay(new Date());
  const showFirstLine = !showTimeSelect && editable && !showMonthYearPicker && !showYearPicker;
  // Initial Values
  const $initialStartDate = convertStringToDate(startDate) ? convertStringToDate(startDate) : now;
  const $initialEndDate = convertStringToDate(endDate) ? convertStringToDate(endDate) : now;
  const $initialMinDate = convertStringToDate(minDate) ? convertStringToDate(minDate) : undefined;
  const $initialMaxDate = convertStringToDate(maxDate) ? convertStringToDate(maxDate) : undefined;
  const $initialOpenToDate = convertStringToDate(openToDate) ? convertStringToDate(openToDate) : ($initialEndDate || now);
  const $initialSelected = (!selected) ? undefined : (selectsRange) ? [
    convertStringToDate(selected?.[0]) ? convertStringToDate(selected?.[0]) : undefined,
    convertStringToDate(selected?.[1]) ? convertStringToDate(selected?.[1]) : convertStringToDate(selected?.[0]) || undefined
  ] : convertStringToDate(selected) ? convertStringToDate(selected) : undefined;
  const $initialExcludeDateIntervals = !excludeDateIntervals ? false : [{start: convertStringToDate(excludeDateIntervals[0]), end: convertStringToDate(excludeDateIntervals[1])}];
  const $initialExcludeDates = !excludeDates ? false : excludeDates.map((date) => convertStringToDate(date));

  // Misc
  const popperModifiers = [
    {
      name: 'changeStrategy',
      enabled: true,
      phase: 'afterWrite',
      fn(e) {
        e.state.options.strategy = "fixed";
        if (e.state.options.strategy !== "fixed") {
          e.instance.update();
        }
      },
    }, {
      name: 'offset', options: {
        offset: [0, 6],
      },
    }];
  const defaultPredefined = {
    range: {
      "Hoje": [setDate(now), setDate(now)],
      "Últimos 7 dias": [setDate(subDays(now, 6)), setDate(now)],
      "Últimos 30 Dias": [setDate(subDays(now, 30)), setDate(now)],
      "Este mês": [setDate(startOfMonth(now)), setDate(endOfMonth(now))],
      "Mês passado": [setDate(startOfMonth(subMonths(now, 1))), setDate(endOfMonth(subMonths(now, 1)))],
      "Este ano": [setDate(startOfYear(now)), setDate(endOfYear(now))],
      "Ano passado": [setDate(startOfYear(subYears(now, 1))), setDate(endOfYear(subYears(now, 1)))],
      "Desde o Iní­cio": [setDate(startOfYear(((minDate && $initialMinDate) ? $initialMinDate : new Date(1950)))), setDate(now)],
    },
    single: {
      "Hoje": setDate(now),
      "Daqui 7 dias": setDate(addDays(now, 7)),
      "Daqui 15 dias": setDate(addDays(now, 15)),
      "Daqui 1 mês": setDate(addMonths(now, 1)),
      "Daqui 3 meses": setDate(addMonths(now, 3)),
      "Daqui 6 meses": setDate(addMonths(now, 6)),
      "Daqui 1 ano": setDate(addYears(now, 1)),
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  /**
   * Quando uma data é selecionada no datepicker
   * @param date 
   */
  const handleChange = (date) => {
    let start = date?.[0] || date;

    // Se houver um maxSelect<Periodo> e for selectsRange, quando selecionar o primeiro periodo, definir interval
    if (selectsRange && (maxSelectDays || maxSelectMonths || maxSelectYears)) {
      if (!date?.[1]) {
        setInterval([{
          start: new Date(start),
          end: startOfDay(addYears(addMonths(addDays(new Date(start), maxSelectDays || 0), maxSelectMonths || 0), maxSelectYears || 0))
        }])
      } else {
        setInterval(undefined);
      }
    }
    setSelected(date);
    setState('manual', (!selectsRange) ? format((date?.[0] || date), 'dd/MM/yyyy') : [format((date?.[0]), 'dd/MM/yyyy'), !date?.[1] ? '' : format((date?.[1]), 'dd/MM/yyyy')]);
    setDirty(true);
    // onClick é a cada seleção, mesmo que não exista ambos valores
    if (typeof onClick === 'function') onClick(date, formatReturn(date))
    // onSelect é quando a data completa é selecionada
    if (((selectsRange && date?.[1]) || (!selectsRange && date)) && typeof onSelect === 'function') onSelect(date, formatReturn(date))
    if (((selectsRange && date?.[1]) || (!selectsRange && date)) && typeof onChange === 'function') onChange(date, formatReturn(date))
  }

  /**
   * Quando uma data predefinida é selecionada
   * @param key 
   */
  const handlePredefined = (key) => {
    let date = state.predefined[key];
    setState('custom', false);

    // Define o valor novo como temp pq ele é sempre valor correto
    setState('temp', {
      date,
      custom: false,
      manual: undefined
    });

    setSelected(date);
    setDirty(true);

    // Executa todos os callbacks
    if (typeof onClick === 'function') onClick(date, formatReturn(date))
    if (typeof onSelect === 'function') onSelect(date, formatReturn(date))
    if (typeof onChange === 'function') onChange(date, formatReturn(date))
  }

  /**
   * Quando a data é digitada manualmente
   * @param ref 
   * @param value 
   */
  const handleManualDate = (ref, value) => {
    const index = {
      'startDate': 0,
      'endDate': 1,
    }

    let date: any = null;
    let tempSelected = state.selected;
    let isValidDate = isValid(parse(value, 'dd/MM/yyyy', new Date()));


    if (isValidDate) {
      date = parse(value, 'dd/MM/yyyy', new Date());
      if (selectsRange) {
        tempSelected[index[ref]] = date;
        if ((tempSelected?.[0] && tempSelected?.[1]) && isAfter(tempSelected[0], tempSelected[1])) {
          tempSelected[index[ref]] = tempSelected[1];
        }
        if ((tempSelected?.[0] && tempSelected?.[1]) && isBefore(tempSelected[1], tempSelected[0])) {
          tempSelected[index[ref]] = tempSelected[0];
        }


        setSelected(tempSelected);
      } else {
        tempSelected = date;
        setSelected(tempSelected);
      }
    } else {
      if (selectsRange) {
        tempSelected[index[ref]] = null;
        setSelected(date);
      }

      if (!selectsRange) {
        tempSelected = null;
        setSelected(date);
      }
    }

    setState('manual', (!selectsRange) ? isValidDate ? format((tempSelected?.[0] || tempSelected), 'dd/MM/yyyy') : null : [!tempSelected?.[0] ? null : format((tempSelected?.[0]), 'dd/MM/yyyy'), !tempSelected?.[1] ? null : format((tempSelected?.[1]), 'dd/MM/yyyy')]);

    // onClick é a cada seleção, mesmo que não exista ambos valores
    if (typeof onClick === 'function') onClick(tempSelected, formatReturn(tempSelected))
    // onSelect é quando a data completa é selecionada
    if (((selectsRange && tempSelected?.[1] && tempSelected?.[0]) || (!selectsRange && tempSelected)) && typeof onSelect === 'function') onSelect(tempSelected, formatReturn(tempSelected))
    if (((selectsRange && tempSelected?.[1] && tempSelected?.[0]) || (!selectsRange && tempSelected)) && typeof onChange === 'function') onChange(tempSelected, formatReturn(tempSelected))

  }


  /**
   * Quando a opção personalizado é selecionada
   */
  const handleSetCustomDate = () => {
    if (state.custom) return;

    setState('custom', true);
    setState('manual', !selectsRange ? null : [null, null]);
    setSelected(!selectsRange ? null : [null, null]);

  }

  /**
   * Quando o dropwon abre ou fecha
   * @param e
   */
  const handleDropdown = (e) => {
    if (e) { return handleOpen() }
    else { handleClose() }
  }

  /**
   * Quando abre o calendário
   */
  const handleOpen = () => {
    let date = (selectsRange) ? [state.startDate, state.endDate] : state.startDate;
    // Define como temporário o valor atual, pra caso precisar usar o restoreDates();
    setState('temp', {
      date,
      custom: state.custom,
      manual: state.manual
    });
    // Callback
    if (typeof onOpen === 'function') onOpen(date, formatReturn(date));
    // Reseta o interval
    setInterval(undefined);
  }

  /**
   * Quando clica fora do calendário
   */
  const handleClose = () => {
    let date = (!selectsRange) ? state.startDate : (!state.endDate) ? state.temp.date : [state.startDate, state.endDate];

    // Senão houver endDate é pq não terminou o select, 
    // redefine o startDate e o endDate com o valor temporario de quando abriu
    if (!state.endDate) {
      restoreDates();
    }

    // Se for custom, e uma das datas manuais estiver errada, reseta
    if (state.custom && selectsRange && (!isValid(parse(state.manual[0], 'dd/MM/yyyy', new Date())) || !isValid(parse(state.manual[1], 'dd/MM/yyyy', new Date())))) {
      setState('manual', state.temp.manual);
    }

    // Callback, somente se o valor mudou que ele vai triggerar
    if ((JSON.stringify(date) !== JSON.stringify(state.temp.date)) || (!state.custom && JSON.stringify(date) === JSON.stringify(state.temp.date))) {
      if (typeof onClose === 'function' && isDirty) onClose(date, formatReturn(date))
      if (typeof onChange === 'function' && isDirty) onChange(date, formatReturn(date))
    }
    // Reset Temp
    setState('temp', false);
    // Reseta o interval
    setInterval(undefined);
    setDirty(false);
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (state.init) {
      setState('startDate', setDate(startDate));
    }
  }, [startDate])

  React.useEffect(() => {
    if (state.init) {
      setState('endDate', setDate(endDate));
    }
  }, [endDate])

  React.useEffect(() => {
    if (state.init) {
      setState('minDate', convertStringToDate(minDate));
    }
  }, [minDate])

  React.useEffect(() => {
    if (state.init) {
      setState('maxDate', convertStringToDate(maxDate));
    }
  }, [maxDate])

  React.useEffect(() => {
    if (state.init && excludeDates) {
      const excludedDates = excludeDates.map((date) => convertStringToDate(date));
      setState('excludedDates', [excludedDates]);
    }
  }, [excludeDates])

  React.useEffect(() => {
    if (state.init && excludeDateIntervals) {
      // subHours na chave end para que o excludeDateIntervals não exclua o último dia (ele considera o horário)
      setState('excludeDateIntervals', excludeDateIntervals);
    }
  }, [excludeDateIntervals])

  React.useEffect(() => {
    const isNull = selected?.[0] === null && selected?.[1] === null || !selected;

    // console.log(selected)

    if (state.init) {
      let Selected = (!selected) ? undefined : (selectsRange) ? [
        convertStringToDate(selected?.[0]) ? convertStringToDate(selected?.[0]) : undefined,
        convertStringToDate(selected?.[1]) ? convertStringToDate(selected?.[1]) : convertStringToDate(selected?.[0]) || undefined
      ] : convertStringToDate(selected) ? convertStringToDate(selected) : undefined;

      setState('startDate', setDate(Selected?.[0] || Selected));
      setState('endDate', setDate(Selected?.[1] || Selected?.[0] || Selected));
      setState('selected', Selected);
      setState('manual', (!selectsRange) ? ((Selected?.[0] || Selected) ? format((Selected?.[0] || Selected), 'dd/MM/yyyy') : null) : [Selected?.[0] ? format((Selected?.[0]), 'dd/MM/yyyy') : null, Selected?.[1] ? format((Selected?.[1]), 'dd/MM/yyyy') : null]);
      return;
    }
    

    if (isNull) {
      setState('startDate', null);
      setState('endDate', null);
      setState('selected', selected);
      return;
    }

  }, [selected])

  React.useEffect(() => {
    if (state.init) {
      setState('openToDate', setDate(openToDate));
    }
  }, [openToDate])

  React.useEffect(() => {
    if (state.init) {
      setState('predefined', typeof predefinedDates === 'object' ? predefinedDates : defaultPredefined[(selectsRange ? 'range' : 'single')]);
    }
  }, [predefinedDates])

  // Init
  React.useEffect(() => {
    let selected = (!selectsRange) ? setDate($initialSelected || $initialStartDate, true) : [setDate($initialSelected?.[0] || $initialStartDate, true), setDate($initialSelected?.[1] || $initialSelected?.[0] || $initialEndDate, true)]
    let startDate = (!selectsRange) ? setDate($initialSelected || $initialStartDate) : setDate($initialSelected?.[0] || $initialStartDate)
    let endDate = (!selectsRange) ? setDate($initialSelected || $initialEndDate) : setDate($initialSelected?.[1] || $initialSelected?.[0] || $initialEndDate)

    setState('excludeDates', $initialExcludeDates);
    setState('excludeDateIntervals', $initialExcludeDateIntervals);
    setState('minDate', $initialMinDate);
    setState('maxDate', $initialMaxDate);
    setState('selected', selected);
    setState('startDate', startDate);
    setState('endDate', endDate);
    setState('OpenToDate', setDate($initialOpenToDate));
    setState('predefined', typeof predefinedDates === 'object' ? predefinedDates : defaultPredefined[(selectsRange ? 'range' : 'single')]);
    setState('manual', (!selectsRange) ? format(startDate, 'dd/MM/yyyy') : [format(startDate, 'dd/MM/yyyy'), format(endDate, 'dd/MM/yyyy')]);
    setState('init', true);
  }, [])


  // console.log('predefined',state.predefined)
  // console.log('selected',state.selected)
  // console.log('JSON.stringify(state.selected)',JSON.stringify(state.selected))
  // console.log(state.excludeDateIntervals);

  ///////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {!state.init && <></>}
      {state.init && (<>
        <Dropdown onToggle={handleDropdown} autoClose="outside">
          <Dropdown.Toggle as="div" id="dropdown-basic" className={styles.dropdownToggle}>
            {children && children}
            {!children && (<>
              <div className={`form-group ${className || ''}`}>
                <div className="form-icon"><i className="fa fa-calendar-alt ms-0" /></div>
                <div className={`form-control cursor-pointer d-flex align-items-center`}>
                  {(typeof dateFormat === 'function') && <span className={styles.date}>{placeholderFormat()}</span>}
                  {!(typeof dateFormat === 'function') && (<>
                    {selectsRange && <>
                      {(state.selected[0] || state.selected[1]) && <>
                        <span className={styles.date}>
                          {state.selected[0] && format(state.selected[0], placeholderFormat(), { locale: ptBR })}
                          <span className="mx-2">—</span>
                          {state.selected[1] && format(state.selected[1], placeholderFormat(), { locale: ptBR })}
                        </span>
                      </>}
                      {(!state.selected[0] && !state.selected[1]) && <>
                        <span className={styles.placeholder}>Selecione o perí­odo</span>
                      </>}
                    </>}
                    {!selectsRange && <>
                      {state.selected && <>
                        <span className={styles.date}>
                          {format(state.selected, placeholderFormat(), { locale: ptBR })}
                        </span>
                      </>}
                      {!state.selected && <span className={styles.placeholder}>Selecione a data</span>}
                    </>}
                  </>)}
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </>)}
          </Dropdown.Toggle>

          <Dropdown.Menu className={`dropdown-menu ${styles.dropdownMenu} ${!predefinedDates ? styles.noPredefinedDates : ''} ${!showFirstLine ? styles.noManual : ''} ${showYearPicker ? styles.showYearPicker : ''} ${showMonthYearPicker ? styles.showMonthYearPicker : ''} ${showTimeSelect ? styles.showTimeSelect : ''}`}>
            <div className={styles.container}>
              {!!predefinedDates && <>
                <div className={styles.predefinedSection}>
                  <ul>
                    <li><button className={`d-flex align-items-center ${state.custom ? styles.active : ''} fw-500`} onClick={handleSetCustomDate}>Personalizado <i className="fa fa-angle-right ms-auto fs-10 unstyled"></i></button></li>
                    <li className={styles.divider}></li>
                    {state.predefined && Object.keys(state.predefined).map((key, index) => (
                      <li key={index}>
                        <button
                          className={((JSON.stringify(state.predefined[key]) === JSON.stringify(state.selected) && !state.custom) ? styles.active : '')}
                          onClick={(e) => handlePredefined(key)}
                        >
                          {key}
                        </button>
                      </li>
                    ))}

                  </ul>
                </div>
              </>}
              {(state.custom || !predefinedDates) && <>
                <div className={styles.calendarSection}>
                  {(showFirstLine && !showMonthYearPicker && !showYearPicker) && <>
                    <div className={styles.manualInputs}>
                      {/* <div className={`${styles.formControl} form-control`}> */}
                      {selectsRange && <>
                        <div className={styles.inputGroup}>
                          <NumberFormat
                            className={styles.input}
                            format="##/##/####"
                            placeholder="__/__/____"
                            value={state.manual[0]}
                            onChange={(e) => handleManualDate('startDate', e.target.value)}
                            mask={'_'}
                          />
                          <i className="fa fa-pen"></i>
                        </div>
                        <span className="mx-2">à</span>
                        <div className={styles.inputGroup}>
                          <NumberFormat
                            className={styles.input}
                            format="##/##/####"
                            placeholder="__/__/____"
                            value={state.manual[1]}
                            onChange={(e) => handleManualDate('endDate', e.target.value)}
                            mask={'_'}
                          />
                          <i className="fa fa-pen"></i>
                        </div>
                      </>}
                      {!selectsRange && <>
                        <div className={styles.inputGroup}>
                          <NumberFormat
                            className={styles.input}
                            format="##/##/####"
                            placeholder="__/__/____"
                            value={state.manual}
                            onChange={(e) => handleManualDate('startDate', e.target.value)}
                            mask={'_'}
                          />
                          <i className="fa fa-pen"></i>
                        </div>
                      </>}
                      {/* </div> */}
                    </div>
                  </>}
                  <DatePicker
                    startDate={state.startDate}
                    endDate={state.endDate}
                    minDate={state.minDate}
                    maxDate={state.maxDate}
                    openToDate={state.openToDate || state.endDate}
                    // excludeDates={state.excludeDates}
                    // excludeDateIntervals={state.excludeDateIntervals}

                    selectsRange={!showTimeSelect && selectsRange}

                    shouldCloseOnSelect={shouldCloseOnSelect}
                    showYearPicker={showYearPicker}
                    showMonthYearPicker={showMonthYearPicker}

                    filterTime={filterTime}
                    timeIntervals={timeIntervals}
                    showTimeSelect={showTimeSelect}

                    monthsShown={1}
                    popperPlacement={position}
                    inline={true}

                    onChange={handleChange}
                    onCalendarClose={handleClose}
                    onCalendarOpen={handleOpen}

                    // Unchangeable
                    locale="ptBR"
                    ref={calendarRef}
                    isClearable={false}
                    includeDateIntervals={interval}
                    popperModifiers={popperModifiers}
                    calendarContainer={({ children }) => <div className={styles.calendarContainer}>{children}</div>}
                    selected={state.startDate}
                    disabled={disabled}
                    disabledKeyboardNavigation
                  />
                </div>
              </>}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>)
      }
    </>
  );
};

export default React.memo(FzDatePicker);