import React from 'react';

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import Loading from '@Components/Loading';
import styles from "./styles.module.scss";


/**
 * 
 * @param title 'Atenção'
 * @param message 'Carregando...'
 * @returns 
 */
const SwalLoading = (title:string = 'Atenção', message:string|boolean = false) => {

  const MySwal = withReactContent(Swal);

  const Component = () => {
    return (
      <div className='mt-1 mb-3'>
        <Loading className='mx-auto' size={100}/>
        <h1 className={styles.title}>{title}</h1>
        {message && <p>{message}</p>}
      </div>
    )
  }

  return MySwal.fire({
    html: <Component/>,
    width: 460,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showConfirmButton: false,
    customClass: {
      container: styles.container
    }
  });
}

export default SwalLoading;

