import React from 'react';
import styles from './styles.module.scss';

import Swal from 'sweetalert2';
import { orderBy } from 'lodash';
import { format, parse } from 'date-fns';
import WebApi from '@Services/WebApi';
import { formatCurrency } from '@Services/Helpers';
import { useAuth } from '@Contexts/Auth';
import Loading from '@Components/Loading';

interface ComponentProps {
  maxHeight?: any;
}

const ExtratoPontos = ({ maxHeight }: ComponentProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [extrato, setExtrato]: any = React.useState([]);
  const { parceiro } = useAuth();

  const origem = {
    87: 'Meu Fidelizi',
    84: 'Vale Pontos',
    15: 'Vale Pontos',
    132: 'Cielo Lio',
    385: 'Api de Integração',
    824: 'Migração do Programa de Fidelidade',
    133: 'Importação',
    31: 'Fidelizi Lite',
    28: 'Pesquisa',
    45: 'Plataforma de Ofertas',
    54: 'Prêmio Surpresa',
    34: 'Email Marketing / Pilotos Automáticos',
    55: 'Régua de WhatsApp',
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Requests
  //////////////////////////////////////////////////////////////////////////////////////
  const requestData = () => {
    setLoading(true);

    WebApi.get(`/clientes/estabelecimentos/${parceiro.id_parceiro}/extrato`).then(res => {

      let data = res.data;
      data = orderBy([...data.entradas, ...data.saidas, ...data.expirados], ['data_evento'], ['asc']);
      let pontos = 0;
      data = data.map(item => {
        pontos += item.qtd;
        return { ...item, total: pontos }
      });
      setExtrato(orderBy(data, ['data_evento'], ['desc']));

    }).catch(err => {
      Swal.fire('Oops!', 'Ocorreu um problema ao carregar o extrato de seu cliente, tente novamente ou contate o suporte técnico.', 'error');
    }).finally(() => setLoading(false));
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    requestData();
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    <div className="table-responsive" style={{ maxHeight: maxHeight || 'auto' }}>
      {isLoading && <Loading />}
      {!isLoading && <>
        {extrato.length === 0 && <h5 className="text-purple-3 text-center">Este cliente ainda não possui pontuações.</h5>}
        {extrato.length > 0 && <>
          <table className={`table hoverable sticky-header ${styles.table}`}>
            <thead>
              <tr>
                <th className='text-center' style={{ width: '136px' }}>Evento</th>
                <th className='text-center' style={{ width: '120px' }}>Data</th>
                <th>Detalhes</th>
                <th>Atendende</th>
                <th className='text-right'>Quantidade</th>
                <th className='text-center' style={{ width: '64px' }}>Saldo</th>
              </tr>
            </thead>
            <tbody>
              {extrato.map((item, index) => {
                let data_evento = parse(item.data_evento, 'yyyy-MM-dd HH:mm:ss', new Date());
                return (
                  <tr key={index} className={styles[item.evento]}>
                    {/* Evento */}
                    <td className='text-center'>
                      {item.evento === 'entrada' && <span className="tag text-white bg-green-6">Ganhou</span>}
                      {item.evento === 'saida' && <span className="tag text-white bg-red-6">Resgate</span>}
                      {item.evento === 'expirado' && <span className="tag text-white bg-gray-3">Expirou</span>}
                      <br />
                      <span className="small">
                        {item.evento !== 'expirado' && <>{origem?.[item.id_tag] || 'Totem Fidelizi'}</>}
                        {item.evento === 'expirado' && <>Sistema</>}
                      </span>
                    </td>

                    {/* Data */}
                    <td className='text-center'>
                      {format(data_evento, "dd/MM/yyyy")}
                      <br />
                      <span className="small">às {format(data_evento, "HH:mm:ss")}</span>
                    </td>

                    {/* Detalhes */}
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-block">
                          {item.evento === 'entrada' && <>
                            {typeof item.extra_data === 'string' && <>Resgate de brinde: </>}
                            {typeof item.extra_data !== 'string' && <>Gasto na compra:</>}
                          </>}
                          {item.evento === 'saida' && <>Resgate de Prêmio: </>}
                          {item.evento === 'expirado' && <>-</>}
                          <br />
                          <span className="small text-gray-2">
                            {item.evento === 'entrada' && <>
                              {typeof item.extra_data === 'string' && <>{item.extra_data}</>}
                              {typeof item.extra_data !== 'string' && <>{formatCurrency(item.extra_data)}</>}
                            </>}
                            {item.evento === 'saida' && <>{item.extra_data}</>}
                            {" "}
                          </span>
                        </div>
                      </div>
                    </td>

                    {/* Atendente */}
                    <td>
                      {item.id_atendente && <>
                        {item.nome_atendente}
                      </>}
                      {!item.id_atendente && <>-</>}
                    </td>

                    {/* Quantidade */}
                    <td className='text-right'>
                      {item.evento === 'entrada' && "+"}{item.qtd} {parceiro?.nome_ponto || 'pontos'}
                    </td>

                    {/* Saldo */}
                    <td className='text-center fs-16 font-weight-bold'>
                      <div className="d-flex align-items-center">
                        {item.evento === 'entrada' && <i className="fa fa-arrow-up-long text-green-6 fs-18"></i>}
                        {item.evento === 'saida' && <i className="fa fa-arrow-down-long text-red-6 fs-18"></i>}
                        {item.evento === 'expirado' && <i className="fa fa-arrow-down-long text-red-6 fs-18"></i>}
                        <span className="ms-3">{item.total}</span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>}
      </>}
    </div>
  </>)
}

export default React.memo(ExtratoPontos);