
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import LoginPage from "@Pages/Publicas/Login";
import Page404 from "@Pages/Publicas/Page404";
import ResgatePage from "@Pages/Publicas/ResgateCodigo";
import CadastroPage from "@Pages/Publicas/Cadastro";
import WhatsappResgate from "@Pages/Publicas/WhatsappResgate";

import Dashboard from "@Pages/Privadas/Dashboard";
import Estabelecimentos from "@Pages/Privadas/Dashboard/Estabelecimentos";
import MeusDados from "@Pages/Privadas/Dashboard/MeusDados";
import Parceiro from "@Pages/Privadas/Dashboard/Parceiro";
import Pontos from "@Pages/Privadas/Dashboard/Parceiro/Pontos";
import Premios from "@Pages/Privadas/Dashboard/Parceiro/Premios";
import Brindes from "@Pages/Privadas/Dashboard/Parceiro/Brindes";
import Ofertas from "@Pages/Privadas/Dashboard/Parceiro/Ofertas";
import Indica from "@Pages/Privadas/Dashboard/Parceiro/Indica";
import Detalhes from "@Pages/Privadas/Dashboard/Parceiro/Detalhes";

const Router = () => (
  <Routes>
    <Route path="/" element={<LoginPage />}/>
    <Route path="resgatar-ponto" element={<ResgatePage />} />
    <Route path="cadastro" element={<CadastroPage />} />
    <Route path="whatsapp" element={<Outlet />} >
      <Route path="" element={<WhatsappResgate />} />
      <Route path="resgate" element={<WhatsappResgate />} />      
    </Route>
    <Route path="dashboard" element={<Dashboard />}> 
      <Route path="" element={<Estabelecimentos />} />
      <Route path="meus-dados" element={<MeusDados />} />
      <Route path=":idParceiro" element={<Parceiro />} >
        <Route path="" element={<Navigate to={'pontos'}/>} />
        <Route path="pontos" element={<Pontos />} />
        <Route path="premios" element={<Premios />} />
        <Route path="brindes" element={<Brindes />} />
        <Route path="ofertas" element={<Ofertas />} />
        <Route path="indique" element={<Indica />} />
        <Route path="detalhes" element={<Detalhes />} />
      </Route>
    </Route>
    <Route path="*" element={<Page404/>} />
  </Routes>
);

export default Router;
