import React from 'react';
import styles from './styles.module.scss';
import AnimatedSquares from "@Components/AnimatedSquares";
import LogoF from '@Components/LogoF';
import Loading from '@Components/Loading';


const CheckIn = props => {

  return (
    <>
      <div className={styles.page}>
        <div className={styles.background}>
          <AnimatedSquares className={styles.squares} color="#fff"/>
        </div>
        <div className={styles.contents}>
          <div className="d-block text-center">
            <div className={styles.logo}>
              <img src="/assets/img/logo_round.png" width="40" alt="" />
              <Loading size={112} color={'#fff'}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckIn;
