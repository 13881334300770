import AnimatedSquares from '@Components/AnimatedSquares';
import { Link } from 'react-router-dom';
import Colors from '@Styles/_variables.scss';
import styles from './style.module.scss';

const Page404 = (props) => {

  return (
    <>
      <AnimatedSquares color={Colors['purple-4']} />
      <div className={`${styles.container} ${props.fullHeight === true ? styles.fullHeight : ''}`}>
        <div className="d-block text-center">
          <img
            src="/assets/img/mago_notfound.png"
            alt="Fidelizi - Programa de Fidelidade"
            width={320}
            height={426}
            className={styles.personagem}
          />
          {/* <div className={styles.block404}>404</div> */}
          <h1 className='fw-700'>Página não encontrada!</h1>
          <p>A página que você procura não foi encontrada ou não existe mais.</p>
          <p><Link to="/" className='text-purple-3'>Clique aqui</Link> para voltar</p>
        </div>
      </div>
    </>
  )
}

export default Page404;