import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import WebApi from "@Services/WebApi";
import Swal from "sweetalert2";
import Loading from "@Components/Loading";
import { useAuth } from "@Contexts/Auth";

const ModalPrivacidade = ({show, setShow}) => {
  const { Logout }            = useAuth();
  const navigate              = useNavigate();
  const [loading, setLoading] = useState(false);
  const onClose               = () => {setShow(false);};

  function handleDeleteAccount() {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Sua conta será deletada e não há como reverter!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, quero deletar.',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        performDelete();
      }
    })
  };


  const performDelete = () => {
    WebApi.post("/clientes/deletar-conta", {validate: 'DELETAR',}).then(res => {
      Logout({
        title: 'Poxa 😭😭😭🙏🙏🙏',
        text: 'Vamos sentir sua falta, mas seus dados foram excluidos com sucesso, agradecemos o tempo que esteve conosco.',
        icon: 'success',
      });
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocorreu um erro ao deletar sua conta! Tente novamente mais tarde.'
      });
    }).finally(() => setLoading(false))
  }

  return (
    <>
      <Modal show={show} centered={true} onHide={onClose} size="lg">
          <Modal.Header closeButton>
              <Modal.Title>Seus dados, suas escolhas</Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-center">
            {loading && (<>
              <Loading centered className="mt-5" size={64} color={'#ff0000'}/>
              <p className="mt-5">Sua conta está sendo deletada, isso pode demorar alguns segundos...</p>
            </>)}
            {!loading && (<>
              <div className="mt-3 fs-16">
                <i className="fa fa-exclamation-circle fs-50 text-purple-4 mb-4"></i>
                <p>Nós mantemos seus dados na plataforma para poder te identificar e dar uma melhor experiência, somente compartilhando no momento de ganhar ou usar os seus pontos.</p>
                <p className="text-red-4 text-underline fw-500">Precisamos deles para manter o seu cadastro aqui.</p>
                <p>Caso queira prosseguir com a não autorização do uso de dados, <span className="text-red-4 text-underline fw-500">sua conta será deletada</span>.</p>
                <p>Seus dados serão totalmente criptografados em nosso banco de dados sem uso e compartilhamento dos mesmos.</p>
                <p>Caso deseje participar de algum programa de fidelidade onde tenha o Fidelizi, <span className="text-red-4 text-underline fw-500">será necessário realizar um novo cadastro.</span></p>
              </div>
            </>)}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100">
              <button onClick={() => handleDeleteAccount()} className="btn btn-bg-red text-white w-100 me-1">
                Deletar conta
              </button>
              <button onClick={onClose} className="btn btn-bg-purple-3 text-white w-100 ms-1">
                Fechar
              </button>
            </div>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPrivacidade;
