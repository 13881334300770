import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "@Contexts/Auth";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";

import SEO from "@Components/SEO";
// import store from "@Store/store";
import Router from "@Services/Router";
console.clear();
const App = () => (
  <HelmetProvider>
    <SEO title="Fidelizi"/>
    <BrowserRouter>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
);
export default App;
