import React, { useState } from "react";
import MaskedInput from "react-maskedinput";
import styles from "../styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
import WebApi from "@Services/WebApi";
import Loading from "@Components/Loading";
import Swal from "sweetalert2";
import SwalLoading from "@Components/SwalLoading";

function ModalPassword({ show, setshow, CPF }) {

  //Carregamento
  const [isLoading, setLoading] = useState(false);
  const [cpf, setCPF] = useState();
  const [recaptcha, setRecaptcha] = useState("");

  function handleRecuperar() {
    setLoading(true);
    SwalLoading('Aguarde...','')
    WebApi.post("/clientes/recuperar-senha", { cpf, recaptcha }).then(res => {
      Swal.fire('Boa!',res.data.message, 'success');
      closeModal();
    }).catch(err => {
      return Swal.fire("Oops!", err?.data?.errors?.[0] || 'Ocorreu um erro desconhecido, tente novamente.', 'error');
    }).finally(setLoading(false));
  }

  function closeModal() {
    setshow(false);
  }

  React.useEffect(() => {
    if (show) {
      setCPF(CPF);
    }
  }, [show])

  return (
    <Modal show={show} onHide={closeModal} centered={true} backdrop={'static'}>
      <Modal.Header closeButton>
        <Modal.Title>Esqueci minha senha</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <div className={styles.inputsWithCaptcha}>
        <p className="text-center fw-500 mb-1">Para recuperar sua senha, insira seu CPF no campo abaixo:</p>
        <div className="input-group mb-3">
          <MaskedInput className="form-control text-center" mask="111.111.111-11" value={cpf} onChange={(e) => setCPF(e.target.value)} />
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          name="recaptcha"
          onChange={(e) => setRecaptcha(e)}
          className={`mt-4 mb-4 ${styles.recaptcha}`}
        />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-gradient-purple w-100 mb-3" disabled={recaptcha === ''} onClick={handleRecuperar}>
          {!isLoading && <span>Recuperar <i className="fa fa-angle-right"></i></span>}
          {isLoading && <Loading color="#fff" size={14} centered/>}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPassword;
