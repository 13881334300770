import React from "react";
import { Accordion } from "react-bootstrap";
import styles from "./styles.module.scss";


const Item = (props) => {
    const debugInfo = JSON.parse(props.evento.debug);

    const eventStyles = {
        "Avaliou o estabelecimento": {
            "icon": "fa-star",
            "color": "#ea15c5"
        },
        "Ganhou pontos": {
            'icon': 'fa-coins',
            'color': '#86B031'
        },
        "Ganhou cashback": {
            "icon": "fa-coins",
            "color": "#86B031"
        },
        "Ganhou brinde da roleta": {
            "icon": "fa-gift",
            "color": "#15d7bf"
        },
        "Resgatou brinde da roleta": {
            "icon": "fa-gift",
            "color": "#1b8130"
        },
        "Expirou brinde da roleta": {
            "icon": "fa-gift",
            "color": "#ff3c3c"
        },
        "Resgatou prêmio da fidelidade": {
            "icon": "fa-gift",
            "color": "#1b8130"
        },
        "Ganhou prêmio de campanha": {
            "icon": "fa-gift",
            "color": "#15d7bf"
        },
        "Resgatou prêmio de campanha": {
            "icon": "fa-gift",
            "color": "#1b8130"
        },
        "Expirou prêmio de campanha": {
            "icon": "fa-gift",
            "color": "#ff3c3c"
        },
        "Ganhou prêmio surpresa": {
            "icon": "fa-trophy",
            "color": "#15d7bf"
        },
        "Resgatou prêmio surpresa": {
            "icon": "fa-trophy",
            "color": "#1b8130"
        },
        "Expirou prêmio surpresa": {
            "icon": "fa-trophy",
            "color": "#ff3c3c"
        },
        "Cadastro": {
            "icon": "fa-child",
            "color": "#1e58c9"
        },
        "Pontos cancelados": {
            "icon": "fa-coins",
            "color": "#ea2b42"
        },
        "Cashback cancelado": {
            "icon": "fa-coins",
            "color": "#ea2b42"
        },
        "SMS enviado": {
            "icon": "fa-comment-sms",
            "color": "#951cec"
        },
        "Respondeu a pesquisa": {
            "icon": "fa-comment-sms",
            "color": "#ce3ddc"
        },
        "Ativou oferta": {
            "icon": "fa-comment-sms",
            "color": "#cb680c"
        },
    };

    const DataGuiaEventos = () => {
        if (props.index > 0 && props.eventos[props.index - 1].data_evento.substring(0, 10) === props.evento.data_evento.substring(0, 10)) {
            return <></>;
        }

        return (
            <div className={styles.item}>
                <div className={styles.line} />
                <div className={styles.date}>
                    {props.evento.data_evento_formatada.substring(0, 10)}
                </div>
            </div>
        );
    }

    return (
        <>
            <DataGuiaEventos />

            <div className={styles.item}>
                <div className={styles.line} />
                <div
                    className={styles.icon}
                    style={{ backgroundColor: eventStyles[props.evento.titulo].color, }}>

                    <i className={`fa ${eventStyles[props.evento.titulo].icon}`} />

                </div>
                <div className={styles.content}>
                    <Accordion
                        className={styles.timelineAccordion} defaultActiveKey={'0'} flush>

                        <Accordion.Item eventKey={String(props.index)}>
                            <Accordion.Header>
                                <div className={styles.cardTimeline}>
                                    <div>
                                        <div className={styles.origem}><i
                                            className="fa fa-podcast" />{props.evento.origem}</div>
                                        <label className={styles.atividade}>
                                            <span>{props.evento.titulo}</span>
                                            <span>{` ${props.evento.data_evento_formatada.substring(11)}`}</span>
                                        </label>
                                    </div>
                                    <hr />
                                    <div className={`${styles.descricao} mt-3 mb-2`}>
                                        {props.evento.descricao}
                                    </div>


                                    {debugInfo.nome_atendente ? (<><span
                                        className="font-weight-bold text-purple-2">ATENDENTE: </span> {debugInfo.nome_atendente}
                                    </>) : undefined}
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                    </Accordion>

                </div>
            </div>
        </>
    )
}

export default Item;
