import React from 'react';
import styles from './styles.module.scss';

const ForcaSenha = ({level}) => {
  
  return (
    <>
      <div className={styles.meter}>
        <div className={`${styles.level} ${(level > 0) ? styles.active : ''}`}/>
        <div className={`${styles.level} ${(level > 1) ? styles.active : ''}`}/>
        <div className={`${styles.level} ${(level > 2) ? styles.active : ''}`}/>
        <div className={`${styles.level} ${(level > 3) ? styles.active : ''}`}/>
      </div>
    </>
  )
}

export default ForcaSenha;