import React from "react";
import { useSelector } from "react-redux";
import Logo from "@Components/Logo";


import { format } from "date-fns";

import styles from "./styles.module.scss";
import SocialMedia from "@Components/SocialMedia";

const Footer = (props) => {
  return (
    <footer className={`${styles.footer}`}>
      <div className={styles.contents}>
        <div className={styles.logo}>
          <img src="/assets/img/fidelizi_logotipo.png" width="90" alt="" />
        </div>
        <p className="mt-2 fs-10 mb-1 text-gray-3 fw-500">Siga nossos canais</p>
        <SocialMedia
          className={styles.social}
          facebook={'https://www.facebook.com/Fidelizii'}
          instagram={'https://www.instagram.com/fidelizi/'}
          youtube={'https://www.youtube.com/channel/UCYWJJLm9JxbB0b2lQdNdnRg'}
          linkedin={'https://www.linkedin.com/company/fidelizii'}
        />
        <hr />
        <p className={styles.termos}>
          Fidelizi {format(new Date(), "Y")} - Todos os direitos reservados -{" "}
          <a className="termosRodape" href="https://fidelizii.com.br/termos-de-uso/" target="_blank">Termos de uso</a>
          {" - "}
          <a className="termosRodape" href="https://fidelizii.com.br/politica-de-privacidade-e-cookies/" target="_blank">Política de Privacidade e Cookies</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
