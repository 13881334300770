import React from "react";
import Tooltip from "@Components/Tooltip";
import styles from "./styles.module.scss";

interface PaginateData {
  current_page: number;
  last_page: number;
  per_page?: number;
  from: number;
  to: number;
  total: number;
}

interface ComponentProps {
  paginateData?: PaginateData;
  onPaginate?: any;
  onPaginatePerPage?: any;
  children?: any;
  showOnTop?: boolean;
  showOnBottom?: boolean;
  disabled?: boolean;
}

const Pagination = ({ paginateData, onPaginate, onPaginatePerPage, children, showOnTop = true, showOnBottom = true, disabled = false }: ComponentProps) => {
  const [value, setValue] = React.useState(paginateData?.current_page || 1);
  const [perPage, setPerPage] = React.useState(paginateData?.per_page || 15);

  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  const callback = (page) => {
    setValue(page);
    if (typeof onPaginate === 'function') onPaginate(page);
  }

  const callbackPerPage = (page) => {
    setPerPage(page);
    if (typeof onPaginate === 'function') onPaginatePerPage(page);
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleInputChange = (e) => {
    e.preventDefault();

    const {
      max,
      value
    } = e.target;

    if ((paginateData?.last_page || 1) === 1) return;

    let val = parseInt(value);
    if (val > max) { return setValue(paginateData?.last_page ?? 1) };
    
    setValue(val);
  }

  const handleInputSubmit = (e) => {
    e.preventDefault();
    callback(!value ? 1 : value);
  }

  const handlePagination = (action) => {
    let current_page = value;
    let last_page = paginateData?.last_page || 1;

    if (last_page === 1) return;
    if (action === 'next-page' && current_page !== last_page) {return callback(current_page+1)};
    if (action === 'prev-page' && current_page !== 1) {return callback(current_page-1)};
    if (action === 'first-page' && current_page !== 1) {return callback(1)};
    if (action === 'last-page' && current_page !== last_page) {return callback(last_page)};
    return callback(1);
  };

  const handleChangePerPage = (e) => {
    callback(1); // Volta pra pagina 1 pra evitar bugs nas outras informações
    callbackPerPage(parseInt(e.target.value));
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Component
  //////////////////////////////////////////////////////////////////////////////////////
  const Pagination = (<>
    <div className={styles.container}>
      <span className="me-2 text-gray-2">Página</span>
      <Tooltip delay={1500} text="Ir para primeira página.">
        <button className={`btn btn-action text-gray-2 me-2 ${styles.btn_action}`} onClick={() => handlePagination('first-page')} disabled={value === 1 || disabled}>
          <i className="fa fa-angles-left"></i>
        </button>
      </Tooltip>
      <Tooltip delay={1500} text="Ir para página anterior.">
        <button className={`btn btn-action text-gray-2 me-1 ${styles.btn_action}`} onClick={() => handlePagination('prev-page')} disabled={value === 1 || disabled}>
          <i className="fa fa-angle-left"></i>
        </button>
      </Tooltip>
      <Tooltip delay={1500} text={`Digite o número da página que deseja ir. Número máximo de páginas: ${paginateData?.last_page}.`}>
        <form onSubmit={handleInputSubmit} className="d-block m-0">
          <input
            type="number"
            className={`form-control text-center text-gray-2 ${styles.input}`}
            step={1}
            min={1}
            max={paginateData?.last_page || 1} 
            disabled={disabled}
            value={value}
            onChange={handleInputChange}
            onBlur={handleInputSubmit}
            onFocus={(event) => event.target.select()}
          />
        </form>
      </Tooltip>
      <Tooltip delay={1500} text="Ir para próxima página.">
        <button className={`btn btn-action text-gray-2 ms-1 ${styles.btn_action}`} onClick={() => handlePagination('next-page')} disabled={value === (paginateData?.last_page || 1) || disabled}>
          <i className="fa fa-angle-right"></i>
        </button>
      </Tooltip>
      <Tooltip delay={1500} text="Ir para última página.">
        <button className={`btn btn-action text-gray-2 ms-2 ${styles.btn_action}`} onClick={() => handlePagination('last-page')} disabled={value === (paginateData?.last_page || 1) || disabled}>
          <i className="fa fa-angles-right"></i>
        </button>
      </Tooltip>
      <span className="ms-2 text-gray-2">... {paginateData?.last_page || 1}</span>
      {
        onPaginatePerPage && (
          <>
            <div className="d-none d-md-flex align-items-center ms-2 text-gray-2">
              | Exibir
              <select className={`${styles.input_per_page} m-2`} disabled={disabled} value={perPage} onChange={(e) => handleChangePerPage(e)} >
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={60}>60</option>
                <option value={100}>100</option>
              </select>
              por página
            </div>
          </>
        )
      }
      {(paginateData?.total || 0) > 0 && (
        <div className="ms-auto fs-12 text-gray-2">
          <p className="mb-0">
            Exibindo {paginateData?.from || 1} — {paginateData?.to || 1} de registros. Total de {(paginateData?.total || 0)}.
          </p>
        </div>
      )}
    </div>
  </>)

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {showOnTop && Pagination}
    {children}
    {showOnBottom && Pagination}
  </>);
};

export default Pagination;
