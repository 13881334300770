import React, { useEffect, useState } from "react";
import WebApi from "@Services/WebApi";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function RegastePage() {
//////////////////////////////////////////////////////////////////////////////////////
// States
//////////////////////////////////////////////////////////////////////////////////////
const location = useLocation();
const [timeLeft, setTimeLeft] = useState(5);
const [url, setUrl] = useState('');

////////////////////////////////////////////////////////////////////
// Effects
////////////////////////////////////////////////////////////////////

useEffect(() => {
  const query = new URLSearchParams(location.search);
  const qrcode = query.get("codigo") ? query.get("codigo").substring(0,7) : false;

  if (qrcode) {
    WebApi.get(`/clientes/publico/dados-qrpontos`, {params: {qrcode: qrcode}})
    .then(({ data }) => {
      setUrl(`${process.env.REACT_APP_WEBSITE_URL}/${data.vale_pontos.slug}/cadastro?source=vp${qrcode}`);
    })
    .catch((err) => Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ', 'error'));

    return;
  }

  setUrl(`${process.env.REACT_APP_WEBSITE_URL}/ganhar`)
}, []);

// Timer para redirecionar pra o cadastro do website
useEffect(() => {
  if (!timeLeft && url) { return window.open(url, '_self')};
  const intervalId = setInterval(() => {
    setTimeLeft(timeLeft - 1);
  }, 1000);
  return () => clearInterval(intervalId);
}, [timeLeft, url]);

//////////////////////////////////////////////////////////////////////
// Render()
//////////////////////////////////////////////////////////////////////
return (
  <>
    <div className="d-flex vw-100 vh-100 align-items-center justify-content-center">
      <div className="d-block text-center">
        <b className="text-purple-3">Você será redirecionado para:</b> <a className="text-purple-1 text-decoration-underline" target="_blank" href={url}>{url}</a>
        <p>Se a página não abrir, verifique se há bloqueio de pop-up no seu navegador ou plugin. Se preferir clique no link acima para acessar a página!</p>
        <p>Essa página fechará em {timeLeft} segundos...</p>
      </div>
    </div>
  </>
);

//////////////////////////////////////////////////////////////////////
// Antigo componente de resgate do vale pontos
//////////////////////////////////////////////////////////////////////

  // const MySwal = withReactContent(Swal);
  // const cameFromExternal = !!sessionStorage.getItem("cpf");
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [recaptcha, setRecaptcha] = useState("");

  // const [step, setStep] = useState("EnterCode");
  // const [qrcode, setQrcode] = useState(sessionStorage.getItem("qrcode") || "");
  // const [cliente, setCliente] = useState(false)
  // const [modular, setModular] = useState(false)
  // const [parceiro, setParceiro] = useState(false)

  // const [cpf, setCpf, isCpfValid] = useStateWithValidation(validarCPF, sessionStorage.getItem("cpf") || "");
  // const [nome, setNome, isNomeValid] = useStateWithValidation(validarNomeCompleto, '');
  // const [email, setEmail, isEmailValid] = useStateWithValidation(validarEmail, '');
  // const [sexo, setSexo, isSexoValid] = useStateWithValidation(validarSexo, '');
  // const [dataNascimento, setDataNascimento, isDataNascValid] = useStateWithValidation(validarData, '');
  // const [celular, setCelular, isCelularValid] = useStateWithValidation(validarCelular, '');
  // const [cep, setCep, isCepValid] = useStateWithValidation((value) => value.replace(/\D/g, '').length === 8, '');
  // const [endereco, setEndereco, isEnderecoValid] = useStateWithValidation((value) => value !== '', '');
  // const [bairro, setBairro, isBairroValid] = useStateWithValidation((value) => value !== '', '');
  // const [estado, setEstado, isEstadoValid] = useStateWithValidation((value) => value !== '', '');
  // const [cidade, setCidade, isCidadeValid] = useStateWithValidation((value) => value !== '', '');
  // const [formIsValid, setFormValid] = useState(false);

  // //////////////////////////////////////////////////////////////////////////////////////
  // // Handlers
  // //////////////////////////////////////////////////////////////////////////////////////
  // const handleResgate = (e) => {
  //   e.preventDefault();

  //   let data = {
  //     cpf: cpf,
  //     qrcode: qrcode,
  //     recaptcha: recaptcha
  //   }


  //   // Objeto com dados do cliente para enviar para cadastro se for cadastro
  //   if (step === 'Cadastro') {
  //     if (!validateFields()) { return; }
  //     data.cliente = {}
  //     data.cliente.nome = nome;
  //     data.cliente.email = email;
  //     data.cliente.cpf = cpf;

  //     if (modular.questao_celular.includes('obrigatorio')) {
  //       data.cliente.celular = celular || '+55';
  //     }
  //     if (modular.questao_data_nascimento.includes('obrigatorio')) {
  //       data.cliente.data_nascimento = dataNascimento;
  //     }
  //     if (modular.questao_sexo.includes('obrigatorio')) {
  //       data.cliente.sexo = sexo;
  //     }
  //     if (modular.questao_localizacao.includes('obrigatorio')) {
  //       data.cliente.cep = cep;
  //       data.cliente.endereco = endereco;
  //       data.cliente.bairro = bairro;
  //       data.cliente.id_estado = estado;
  //       data.cliente.id_cidade = cidade;
  //     }
  //   }

  //   SwalLoading('Ativando Código', '');
  //   WebApi.post("/clientes/resgate-codigo", data).then(res => {
  //     const response = res.data.data;
  //     MySwal.fire({
  //       title: 'Parabéns! 🥳',
  //       html: <SuccessfullCode promocao={response.promocao} point={response.ponto.qtd_ponto} pointName={response.nome_ponto || 'ponto(s)'} />,
  //       icon: 'success'
  //     }).then(input => {
  //       reset();
  //     });
  //   }).catch(err => {
  //     if (err.status === 403 || err.status === 401) {
  //       setCliente(err.data.cliente);
  //       setParceiro(err.data.parceiro);
  //       setModular(err.data.campos_cadastro);
  //       setRecaptcha('');
  //       setStep('Cadastro');
  //       return Swal.close();
  //     }
  //     return Swal.fire("Oops!", err.data.errors[0], 'error');
  //   });
  // };

  // const handleDadosCadastrais = (e) => {
  //   if (e.nome_completo.required) setNome(e.nome_completo.value);
  //   if (e.email.required) setEmail(e.email.value);
  //   if (e.data_nascimento.required) setDataNascimento(e.data_nascimento.value);
  //   if (e.celular.required) setCelular(e.celular.value);
  //   if (e.sexo.required) setSexo(e.sexo.value);
  //   if (e.cep.required) setCep(e.cep.value);
  //   if (e.endereco.required) setEndereco(e.endereco.value);
  //   if (e.bairro.required) setBairro(e.bairro.value);
  //   if (e.estado.required) setEstado(e.estado.value);
  //   if (e.cidade.required) setCidade(e.cidade.value);
  //   setFormValid(e.formIsValid);
  // }

  // //////////////////////////////////////////////////////////////////////////////////////
  // // Helpers
  // //////////////////////////////////////////////////////////////////////////////////////
  // function validateFields() {

  //   if (!isCpfValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'O CPF digitado é inválido.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }
  //   if (!isNomeValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'O nome digitado deve conter pelo menos um sobrenome.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }

  //   if (!isEmailValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'O Email digitado é inválido.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }

  //   if (modular.questao_celular.includes('obrigatorio') && !isCelularValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'O celular digitado é inválido.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }

  //   if (modular.questao_localizacao.includes('obrigatorio')) {
  //     if (!isCepValid || !isEnderecoValid || !isBairroValid || !isCidadeValid || !isEstadoValid) {
  //       Swal.fire({
  //         title: 'Oops!',
  //         text: 'As informações do endereço digitadas estão inválidas,',
  //         icon: 'error'
  //       });
  //       return false;
  //     }
  //   }

  //   if (modular.questao_data_nascimento.includes('obrigatorio') && !isDataNascValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'A data de nascimento digitada é inválida.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }

  //   if (modular.questao_sexo.includes('obrigatorio') && !isSexoValid) {
  //     Swal.fire({
  //       title: 'Oops!',
  //       text: 'O sexo selecionado é inválido.',
  //       icon: 'error'
  //     });
  //     return false;
  //   }

  //   return true;
  // }

  // function reset() {
  //   setStep('EnterCode');
  //   setCliente(false);
  //   setParceiro(false);
  //   setModular(false);
  //   setQrcode('');
  //   sessionStorage.clear();
  // }

  // //////////////////////////////////////////////////////////////////////////////////////
  // // Components
  // /////////////////////////////////////////////////////////////////////////////////////
  // const EnterCode = (<>
  //   <form className="formContainerRegaste" onSubmit={handleResgate}>
  //     <div className={styles.inputsWithCaptcha}>

  //       <div className="form-group">
  //         <label>Digite o código:</label>
  //         <input type="text" onChange={(e) => setQrcode(e.target.value)} value={qrcode} className="form-control text-center" placeholder="Código" />
  //       </div>

  //       <div className="form-group">
  //         <label>{cameFromExternal ? 'Confirme' : 'Digite'} o seu CPF.</label>
  //         <NumberFormat onChange={(e) => setCpf(e.target.value)} value={cpf} className="form-control text-center" format="###.###.###-##" placeholder="___.___.___-__" mask={['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_']} />
  //       </div>
  //       <ReCAPTCHA
  //         sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
  //         name="recaptcha"
  //         onChange={(e) => setRecaptcha(e)}
  //         className={`mt-4 mb-4 ${styles.recaptcha}`}
  //       />
  //       <button disabled={recaptcha === '' || cpf === '' || qrcode === ''} className="btn btn-gradient-purple w-100" type="submit">
  //         CONTINUAR <i className="fa fa-angle-right"></i>
  //       </button>
  //     </div>

  //     <hr />
  //   </form>
  // </>)

  // const Cadastro = (<>
  //   <div className="d-block">
  //     <label className={styles.paraParticipar}>Para ganhar seus pontos, é necessário <b className="text-purple-3">{(!!cliente?.id_cliente && !!cliente?.cartela_atual) ? 'atualizar seus dados' : 'participar do'}</b> Fidelizi em <a className="text-purple-3 fw-500" href={`https://fidelizi.com.br/${parceiro.slug}`} target="_blank">{parceiro.nome}</a>!</label>
  //     <p className={styles.preencha}>Preencha os campos abaixo para participar!</p>
  //     <FormCadastroCliente className="mb-3 text-left" cliente={cliente} questoes={modular} onChange={handleDadosCadastrais} />
  //     <div className="d-flex">
  //       <ReCAPTCHA
  //         sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
  //         name="recaptcha"
  //         onChange={(e) => setRecaptcha(e)}
  //         className={`mx-auto mb-3 ${styles.recaptcha}`}
  //       />
  //     </div>
  //     <button className="btn btn-gradient-purple w-100" onClick={(e) => handleResgate(e)} disabled={recaptcha === ''}>
  //       CONTINUAR <i className="fa fa-angle-right"></i>
  //     </button>
  //     <p className="small text-center mt-2">
  //       Ao participar você concorda e aceita os <a className='text-purple-3' href="https://fidelizii.com.br/termos-de-uso/" target="_blank">termos de uso </a>
  //       e <a className='text-purple-3' href="https://fidelizii.com.br/politica-de-privacidade-e-cookies/" target="_blank">políticas de privacidade </a>
  //       do Fidelizi
  //     </p>
  //   </div>
  // </>);

  // const SuccessfullCode = (props) => (<>
  //   <div className="d-block">
  //     <span>Seu vale pontos foi resgatado com sucesso!</span>
  //     <div className="containerRegate mb-0 pb-0">
  //       {props.promocao.tipo === 'cashback' && <h4>Você ganhou {formatCurrency(parseFloat(props.point / 100).toFixed(2))} em cashback!</h4>}
  //       {props.promocao.tipo !== 'cashback' && <h4>Você ganhou {props.point} {props.pointName}!</h4>}

  //     </div>
  //   </div>
  // </>);

  // //////////////////////////////////////////////////////////////////////////////////////
  // // Effects
  // //////////////////////////////////////////////////////////////////////////////////////
  // 

  // //////////////////////////////////////////////////////////////////////////////////////
  // // Render()
  // //////////////////////////////////////////////////////////////////////////////////////
  // return (
  //   <>
  //     <SEO
  //       title=""
  //       prefix="Valide seus pontos em nosso programa de fidelidade - Fidelizi"
  //       description="Você realizou uma compra em nosso estabelecimento e ganhou pontos que podem ser trocados por prêmios incríveis! Aproveite!"
  //     />
  //     <LoginBody>
  //       <p className="text-purple-3 fs-13 fw-700">Ganhe pontos através do código fornecido pelo estabelecimento que você participa do programa!</p>
  //       <hr className="dashed mb-4" />
  //       {step === 'EnterCode' && EnterCode}
  //       {step === 'Cadastro' && Cadastro}
  //     </LoginBody>
  //   </>
  // );
}

export default RegastePage;
