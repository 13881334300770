import React from "react";
import Lottie from "react-lottie";
import filelotie from "@Assets/lottie/filelotie.json";
import filelotie2 from "@Assets/lottie/filelotie2.json";
import styles from "./styles.module.scss";

const CardSaldo = ({ tipo, saldoAtual, saldoCarencia, validadePontos, validadeData, nomePonto }) => {

  function currencyFormatter(value) {
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value);
    return `${amount}`;
  }

  return (
    <div className="row">
      <div className="col-sm-6 col-12 mb-3 mb-md-0">
        <div className="border-box d-flex align-items-center">
          <div className={styles.lottieContainer}>
            <Lottie options={{ animationData: filelotie, loop: true, autoplay: true }} />
          </div>
          <div className="d-block">
            <h3 className="text-purple-2 fs-22 m-0 mb-3">Saldo {tipo === 'cashback' && 'Cashback'}</h3>
            <p className="mb-0 fs-20 fw-600 text-green-2">
              <span>{tipo === 'cashback' ? currencyFormatter(saldoAtual) : saldoAtual}</span>
              {tipo != 'cashback' && (nomePonto ? ' ' + nomePonto : ' pontos')}
            </p>
            <span className="mini">(+{tipo === 'cashback' ? currencyFormatter(saldoCarencia) : saldoCarencia} pendentes) </span>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-12">
        <div className="border-box d-flex align-items-center">
          <div className={styles.lottieContainer}>
            <Lottie options={{ animationData: filelotie2, loop: true, autoplay: true, speed: 0.5 }} />
          </div>
          <div className="d-block">
            <h3 className="text-purple-2 fs-22 m-0 mb-3">Próximo Vencimento</h3>
            <p className="mb-0 fs-20 fw-500 text-red-3">
              <span>{tipo === 'cashback' ? currencyFormatter(validadePontos) : validadePontos}</span>
              {tipo != 'cashback' && (nomePonto ? ' ' + nomePonto : ' pontos')}
            </p>
            <span className="cardDateLose">{validadeData && 'vencerão em ' + validadeData} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSaldo;
