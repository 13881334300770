import React from 'react';
import { formatCurrency } from '@Services/Helpers';

const ResumoPrograma = ({programa, className}): React.ReactElement => {
  
  return (
    <>
      <ul className={className}>
          {/* Odometro */}
          {( programa.tipo === 'odometro' && programa.odometro_fixo === 0 ) && (<>
            {/* Conversão de dinheiro habilitado */}
            {( programa.converter_ponto_dinheiro === 1) && (<>
              <li> A Cada {formatCurrency(parseFloat(programa.conversao_dinheiro))} gasto você ganha { programa.conversao_ponto} {programa.conversao_ponto > 1 ? 'pontos' : 'ponto'}; </li>
              <li> Seus pontos tem validade de {programa.odometro_validade} {programa.odometro_validade === 1 ? 'mês' : 'meses'}; </li>
            </>)}
            {/* Conversão de dinheiro desabilitado */}
            {( programa.converter_ponto_dinheiro === 0) && (<>
              <li> A Cada visita você ganha vários pontos; </li>
              <li> Seus pontos tem validade de {programa.odometro_validade} {programa.odometro_validade === 1 ? 'mês' : 'meses'}; </li>
            </>)}
            <li>Pontos conquistados passam a valer {programa.periodo_carencia_pontos === 0 ? 'imediatamente' : `depois de ${programa.periodo_carencia_pontos} dia${programa.periodo_carencia_pontos === 1 && 's'}`}.</li>
          </>)}
          {/* Checkin */}
          {( programa.tipo === 'odometro' && programa.odometro_fixo === 1 ) && (<>
            <li> A cada visita você ganha {programa.odometro_fixo_pontos} {programa.odometro_fixo_pontos > 1 ? 'pontos' : 'ponto'}; </li>
            <li> Seus pontos tem validade de {programa.odometro_validade} {programa.odometro_validade === 1 ? 'mês' : 'meses'}; </li>
            <li> Pontos conquistados passam a valer {programa.periodo_carencia_pontos === 0 ? 'imediatamente' : `depois de ${programa.periodo_carencia_pontos} dia${programa.periodo_carencia_pontos === 1 && 's'}`}.</li>
          </>)}
          {/* Cashback */}
          {( programa.tipo === 'cashback' ) && (<>
            { Number(programa.valor_maximo_cashback_ganho) > 0 && <li>Ganhe até R${programa.valor_maximo_cashback_ganho} em cashback;</li>}
            { (programa.resgate_cashback_automatico === 1) && (<>
              <li> Ganhe {parseFloat(programa.percentual_cashback).toFixed(0)}% do valor gasto {Number(programa.valor_minimo_cashback) > 0 ? `acima de R$${programa.valor_minimo_cashback}` : ''} em desconto na sua compra; </li>
            </>)}
            { (programa.resgate_cashback_automatico !== 1) && (<>
              <li> Receba de volta {parseFloat(programa.percentual_cashback).toFixed(0)}% do valor gasto {Number(programa.valor_minimo_cashback) > 0 ? `acima de R$${programa.valor_minimo_cashback}` : ''} para usar quando quiser; </li>
              <li> Cashback conquistado passa a valer {programa.periodo_carencia_pontos === 0 ? 'imediatamente' : `depois de ${programa.periodo_carencia_pontos === 1 ? '1 dia' : `${programa.periodo_carencia_pontos} dias`}`}.</li>
              <li> Seu saldo tem validade de {programa.odometro_validade} {programa.odometro_validade === 1 ? 'mês' : 'meses'}. </li>
            </>)}
          </>)}

      </ul>
    </>
  )
}

export default ResumoPrograma;