import { useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import styles from "./styles.module.scss";

const PartnerDropdown = ({parceiros, current, className}) => {
  const [search, setSearch] = useState('');

  return (<>
    <div className={`${className || ''} ${styles.container}`}>
      <Dropdown className="text-right">
        <Dropdown.Toggle>
          <i className="fa fa-store me-3 ps-2"></i> <span className="me-2">{current ? current.nome : 'Ir para'}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.menu}>
          <div className="drop-menu">
            <ul className={styles.lista}>
              <li className={styles.search}>
                <div className={styles.input}>
                  <i className="fa fa-search"></i>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-control inputSearch"
                    placeholder="Buscar..."
                    aria-label="Usuário"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </li>
              {_.filter(_.orderBy(parceiros, ['nome','asc']), (item, key) => item.nome.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase())).map((estabelecimento) => (
                <li key={estabelecimento.id_parceiro}>
                  <Link className={`py-2 py-lg-0 ${styles.parceiro}`} to={`/dashboard/${estabelecimento.id_parceiro}`}>
                    {estabelecimento.nome}
                  </Link>
                </li>
              ))}
            </ul>

          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </>)
}

export default PartnerDropdown;