import React from 'react';
import WebApi from '@Services/WebApi';
import { useParams, useNavigate, generatePath } from "react-router-dom";
import { useAuth } from "@Contexts/Auth";
import { find } from "lodash"
import { toast } from "react-toastify";


export const ResponseInterceptor = ({children}) => {
  const { idParceiro } = useParams();
  const navigate = useNavigate();
  const {
    Logout,
    token
  } = useAuth();
  /////////////////////////////////////////////////////////////////////////////////////
  // RequestInterceptor
  /////////////////////////////////////////////////////////////////////////////////////
  const RequestInterceptor = async (config) => {
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  };

  /////////////////////////////////////////////////////////////////////////////////////
  // ResponseSuccessInterceptor
  /////////////////////////////////////////////////////////////////////////////////////
  const ResponseSuccessInterceptor = (response) => {
    return response;
  };

  /////////////////////////////////////////////////////////////////////////////////////
  // ResponseErrorInterceptor
  /////////////////////////////////////////////////////////////////////////////////////
  const ResponseErrorInterceptor = (response) => {
    // Bad Request
    console.log(response);
    if (response?.status === 400) {
        console.warn("Bad Request");
    }

    // Unauthorized
    if (response?.status === 401) {
        console.warn("Unauthorized");
        Logout({
          title: 'Oops',
          text: 'Sessão expirada!',
          icon: 'error'
        });
    }
      
      // Forbidden
    if (response?.status === 403) {
      console.warn("Forbidden");
    }

    // Internal Server Error
    if (response?.status === 500) {
      toast.error('Ocorreu um erro desconhecido, entre em contato com o suporte!', {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.warn("Internal Server Error");
    }
    return Promise.reject(response);
  };


  React.useEffect(() => {
    const interceptorReqId = WebApi.interceptors.request.use(RequestInterceptor);
    const interceptorResId = WebApi.interceptors.response.use(
        ResponseSuccessInterceptor,
        ResponseErrorInterceptor
    );
    return () => {
      WebApi.interceptors.request.eject(interceptorReqId);
      WebApi.interceptors.response.eject(interceptorResId);
    };
  }, []);

  // No need to render anything here!
  return null;
}
