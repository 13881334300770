import React from 'react';
import { useNavigate, useLocation, Navigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import WebApi from '@Services/WebApi';

export const LOGIN_TOKEN = '@fdz-cli-token';
export const AuthContext = React.createContext({});
export const useAuth = () => React.useContext(AuthContext).Auth;


/////////////////////////////////////////////////////////////////////////////////////
// Reducer
/////////////////////////////////////////////////////////////////////////////////////
const INITIAL_STATE = {
  user       : false,
  rede       : false,
  parceiro   : false,
  permissions: false,
  intercom   : false,
}

const reducer = (state = INITIAL_STATE, action) => {
  const {action: act, context, data} = action;

  if (act === 'logout') {
    return INITIAL_STATE;
  }

  if (act === 'update' && context && data) {
    return {...state, [context]: {...state[context], ...data}}
  }
  if (act === 'set' && context) {
    return {...state, [context]: data}
  }

  return state;
}


/////////////////////////////////////////////////////////////////////////////////////
// Components
/////////////////////////////////////////////////////////////////////////////////////
export const RequireAuth = ({ children }) => {
  let { user }   = useAuth();
  let location = useLocation();
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/checkin" state={{ from: location }} replace />;
  }

  return children;
}

/////////////////////////////////////////////////////////////////////////////////////
// Provider
/////////////////////////////////////////////////////////////////////////////////////
export const AuthProvider = ({ children }) => {
  const navigate          = useNavigate();
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  // Shortcode do Dispatch
  const trigger = (action, context, data) => dispatch({action, context, data});

  /////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////////////////
  const handleLogin = async (user) => {
    return await WebApi.post('/clientes/auth', user).then(res => {
      const token = res.data?.cliente?.token?.token;
      if (!token) {throw 'Ocorreu um erro ao obter seu token de acesso.';}
    
      localStorage.setItem(LOGIN_TOKEN, token);
      return true;
    }).catch(err => {
      console.log(err);
      throw err?.data?.errors?.[0] || err || 'Ocorreu um erro desconhecido. 😔'
    });
  }

  const handleLogout = (swalParams) => {
    dispatch({action: 'logout'});
    if (swalParams) { 
      Swal.fire({
        title           : swalParams?.title ||'Atenção',
        text            : swalParams?.text ||'Obrigado por utilizar o Fidelizi Esperamos te ver em breve! 😃',
        icon            : swalParams?.icon ||'success',
        timer           : 5000,
        timerProgressBar: true,
      });
    }
    localStorage.removeItem(LOGIN_TOKEN);
    return navigate("/");
  }

  const handleAuthenticate = async () => {
    return await WebApi.get(`/clientes/auth`).then(res => {
      let usuario               = res.data.cliente;
          usuario.iniciais      = usuario.nome.split(" ")[0][0] + '' + usuario.nome.split(" ")[1][0];
          usuario.primeiro_nome = usuario.nome.split(" ")[0];
          usuario.nome_curto    = usuario.nome.split(" ")[0] + ' ' + usuario.nome.split(" ")[1];

      return {
        cliente: usuario,
        rede   : res.data.estabelecimentos
      };
    }).catch(err => {
      throw err?.data?.errors?.[0] || err || 'Ocorreu um erro desconhecido. 😔';
    });
  }

  const Auth = {
    user           : state.user,
    rede           : state.rede,
    parceiro       : state.parceiro,
    token          : localStorage.getItem(LOGIN_TOKEN),
    isAuthenticated: !!localStorage.getItem(LOGIN_TOKEN),
    Authenticate   : ()           => handleAuthenticate(),
    Login          : (user)       => handleLogin(user),
    Logout         : (swalParams) => handleLogout(swalParams),
    SetUser        : (user)       => trigger('set', 'user', user),
    SetParceiro    : (parceiro)   => trigger('set', 'parceiro', parceiro),
    SetRede        : (rede)       => trigger('set', 'rede', rede),
    UpdateUser     : (data)       => trigger('update', 'user', data),
    UpdateParceiro : (data)       => trigger('update', 'parceiro', data),
    UpdateRede     : (data)       => trigger('update', 'rede', data),
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (
    <AuthContext.Provider value={{Auth}}>
      {children}
    </AuthContext.Provider>
  );
};

