import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Swal from "sweetalert2";
import WebApi from "@Services/WebApi";
import { Link } from "react-router-dom";
import ModalPrivacidade from '@Components/ModalPrivacidade'
import { useAuth } from "@Contexts/Auth";
import FormCadastroCliente from "@Components/FormCadastroCliente";
import useStateWithValidation from '@Hooks/useStateWithValidation';
import SwalLoading from "@Components/SwalLoading";
import NProgress from "@Components/NProgress";
import { orderBy, find, findIndex } from "lodash";
import { 
  validarCPF, 
  validarNomeCompleto, 
  validarEmail, 
  validarCelular, 
  validarData, 
  validarSexo 
} from "@Services/Helpers";

const MeusDados = () => {
  const {
    user,
    rede,
    UpdateUser,
  } = useAuth()

  const [nome, setNome, isNomeValid]                         = useStateWithValidation(validarNomeCompleto,'');
  const [email, setEmail, isEmailValid]                      = useStateWithValidation(validarEmail,'');
  const [sexo, setSexo, isSexoValid]                         = useStateWithValidation(validarSexo,'');
  const [sexoOutro, setSexoOutro]                            = useState('');
  const [dataNascimento, setDataNascimento, isDataNascValid] = useStateWithValidation(validarData,'');
  const [celular, setCelular, isCelularValid]                = useStateWithValidation(validarCelular,'');
  const [cep, setCep, isCepValid]                            = useStateWithValidation((value) => value.replace(/\D/g, '').length === 8,'');
  const [endereco, setEndereco, isEnderecoValid]             = useStateWithValidation((value) => value !== '', '');
  const [bairro, setBairro, isBairroValid]                   = useStateWithValidation((value) => value !== '', '');
  const [estado, setEstado, isEstadoValid]                   = useStateWithValidation((value) => value !== '', '');
  const [cidade, setCidade, isCidadeValid]                   = useStateWithValidation((value) => value !== '', '');
  const [formIsValid, setFormValid]                          = useState(false);

  const [isOpenModalPrivacidade, showModalPrivacidade] = useState(false);
  const [isLoading, setLoading]                          = useState(false);
  const [tab, setTab]                                  = useState('DadosCadastrais');
  
  const modular = {
    questao_data_nascimento: 'obrigatorio',
    questao_celular        : 'obrigatorio',
    questao_sexo           : 'obrigatorio',
    questao_localizacao    : 'obrigatorio',
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////////////
  function validateFields() {

    if (!isNomeValid) {
        Swal.fire({
          title: 'Oops!',
          text : 'O nome digitado deve conter pelo menos um sobrenome.',
          icon : 'error'
        });
        return false;
    }

    if (!isEmailValid) {
        Swal.fire({
          title: 'Oops!',
          text : 'O Email digitado é inválido.',
          icon : 'error'
        });
        return false;
    }

    if (modular.questao_celular.includes('obrigatorio') && !isCelularValid) {
        Swal.fire({
          title: 'Oops!',
          text : 'O celular digitado é inválido.',
          icon : 'error'
        });
        return false;
    }

    if (modular.questao_localizacao.includes('obrigatorio')) {
        if (!isCepValid || !isEnderecoValid || !isBairroValid || !isCidadeValid || !isEstadoValid) {
            Swal.fire({
              title: 'Oops!', 
              text : 'As informações do endereço digitadas estão inválidas,',
              icon : 'error'
            });
            return false;
        }
    }

    if (modular.questao_data_nascimento.includes('obrigatorio') && !isDataNascValid) {
        Swal.fire({
          title: 'Oops!',
          text : 'A data de nascimento digitada é inválida.',
          icon : 'error'
        });
        return false;
    }

    if (modular.questao_sexo.includes('obrigatorio') && !isSexoValid) {
        Swal.fire({
          title: 'Oops!',
          text : 'O gênero selecionado é inválido.',
          icon : 'error'
        });
        return false;
    }

    if (modular.questao_sexo.includes('obrigatorio') && sexo === "O" && sexoOutro === '') {
      Swal.fire({
        title: 'Oops!',
        text : 'O gênero digitado não pode ser um valor vazio.',
        icon : 'error'
      });
      return false;
    }

    return true;
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleDadosCadastrais = (e) => {
    if (e.nome_completo.required) setNome(e.nome_completo.value);
    if (e.email.required) setEmail(e.email.value);
    if (e.data_nascimento.required) setDataNascimento(e.data_nascimento.value);
    if (e.celular.required) setCelular(e.celular.value);
    if (e.sexo.required) setSexo(e.sexo.value);
    if (e.sexo_outro.required) setSexoOutro(e.sexo_outro.value);
    if (e.cep.required) setCep(e.cep.value);
    if (e.endereco.required) setEndereco(e.endereco.value);
    if (e.bairro.required) setBairro(e.bairro.value);
    if (e.estado.required) setEstado(e.estado.value);
    if (e.cidade.required) setCidade(e.cidade.value);
    setFormValid(e.formIsValid);
  }

  const handleCadastro = (e) => {
    e.preventDefault();

    if (!validateFields()) {return;}

    let data = {
      id_cliente: user.id_cliente,
      nome,
      email
    }

    SwalLoading('Atualizando Dados!','');      

    if (modular.questao_celular.includes('obrigatorio')) {
      data.celular = celular || '+55';
    }
    if (modular.questao_data_nascimento.includes('obrigatorio')) {
      data.data_nascimento = dataNascimento;
    }
    if (modular.questao_sexo.includes('obrigatorio')) {
      data.sexo = sexo;
      data.sexo_outro = sexoOutro;
    }
    if (modular.questao_localizacao.includes('obrigatorio')) {
      data.cep       = cep;
      data.endereco  = endereco;
      data.bairro    = bairro;
      data.id_estado = estado;
      data.id_cidade = cidade;
    }

    SwalLoading('Enviando seus dados!','');
    WebApi.post("/clientes/editar", data).then(res => {
      return Swal.fire({
        title: 'Boa! 🥳',
        html: "Dados atualizados com sucesso!",
        icon: 'success'
      })
    }).catch(err => {
      Swal.close();
      return Swal.fire("Oops!",err.data.errors[0],'error');
    });
  }

  const handleOpt = (id_parceiro) => {
    setLoading(true);
    WebApi.post("/clientes/notifications", {id_cliente: user.id_cliente, id_parceiro: id_parceiro || false}).then(res => {
      let optout = res.data.optout;
      if (!id_parceiro) {
        UpdateUser({optout});
      } else {
        let campanhas = [...user.campanhas_optout]
        let index = findIndex(campanhas, {id_parceiro:id_parceiro});
        if (index === -1) {
          campanhas.push({
            id_parceiro,
            optout
          });
        } else {
          campanhas[index].optout = optout;
        }
        console.log(campanhas);
        console.log(index);
        UpdateUser({campanhas_optout: campanhas});
      }
    }).catch(err => {
      Swal.fire('Oops!','Ocorreu um erro ao alterar suas configurações, tente novamente.');
    }).finally(() => setLoading(false));
  }



  //////////////////////////////////////////////////////////////////////////////////////
  // Components
  //////////////////////////////////////////////////////////////////////////////////////
  const DadosCadastrais = (<>
    <div className={styles["option-content"]}>
      <div className="animate fadeIn w-100">
        <div className="text-center">
          <p>Mantenha seus dados sempre atualizados para participar de promoções e <br/>ofertas exclusivas dos estabelecimentos em que você participa!</p>
        </div>
        <FormCadastroCliente className="mb-3" cliente={{...user, id_cliente: false}} questoes={modular} onChange={handleDadosCadastrais}/>
        <div className="text-center w-100">
          <button name="salvar" onClick={handleCadastro} className="btn small btn-bg-purple-3 text-white px-5">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </>);
  const Notificacoes = (<>
    <div className={styles["option-content"]}>
      <div className="animate fadeIn w-100">
        <div className="text-center">
          <p>Durante a sua participação nos programas de fidelidade do Fidelizi, você pode receber e-mails informativos e promocionais dos estabelecimentos e o andamento da sua cartela de pontos, assim como e-mails relacionados ao Fidelizi.</p>
          <p>Abaixo você pode configurar de quem você deseja receber e-mails:</p>
        </div>
        <div className="text-center mb-3"><img src={'/assets/img/notificacoes.png'} /></div>
        {/* <label className={`${styles['check-form']} d-flex align-items-center justify-content-center W-100`}>
          <input type="checkbox" name="notificacoes" checked={user.optout === 1} value={user.optout} onChange={() => handleOpt()} className="option-checkbox"/>
          <span>Desejo receber todos os emails transacionais enviados pelo Fidelizi</span>
        </label> */}
        <table className={`${styles.table} table table-striped table-hover table-responsive`}>
          <tbody>
            <tr onClick={() => handleOpt()} className={styles.firstrow}>
              <td><div className={`${styles.check} ${user.optout === 1 ? styles.checked : ''}`}/></td>
              <td className="text-purple-2 fw-500">Fidelizi</td>
              <td className="text-purple-2 fw-500">Receba e-mails a cada pontuação em um estabelecimento ou sobre informações de nossos produtos.</td>
            </tr>
            {orderBy(rede, ['nome'], ['asc']).map((item,index) => (<React.Fragment key={index}>
              <tr onClick={() => handleOpt(item.id_parceiro)}>
                <td><div className={`${styles.check} ${find(user.campanhas_optout,{id_parceiro: item.id_parceiro})?.optout !== 1 ? styles.checked : ''}`}/></td>
                <td>{item.nome}</td>
                <td>Receba e-mails promocionais, propagandas e até mesmo brindes do estabelecimento.</td>
              </tr>
            </React.Fragment>))}
          </tbody>
        </table>
      </div>
    </div>
  </>);
  const Privacidade = (<>
    <div className={styles["option-content"]}>
      <div className="animate fadeIn w-100">
        <div className="text-center">
          <p>Precisamos dos dados cadastrais para manter seu cadastro no Fidelizi ativo, já os dados adicionais para que você possa ganhar ou resgatar benefícios nos estabelecimentos em que você participa do programa de fidelidade.</p>
          <p>Você tem total controle sobre os dados que compartilha com cada estabelecimento.</p>
        </div>
        <div className={`${styles['space-list']} d-flex align-items-start justify-content-around`}>
          <div className={`${styles.card} text-left float-left`}>
            <h2 className="fs-14 text-purple-2">Dados básicos de cadastro</h2>
            <ul>
              <li>Nome Completo</li>
              <li>CPF</li>
              <li>E-mail</li>
              <li>Celular *</li>
              <li>Data de Nascimento *</li>
              <li>Endereço *</li>
              <li>Sexo *</li>
            </ul>
          </div>
          <div className={`${styles.card} text-left float-left`}>
            <h2 className="fs-14 text-purple-2">Para acumular pontos e resgatar benefícios</h2>
            <ul>
              <li>CPF</li>
              <li>Celular *</li>
              <li>Data de Nascimento *</li>
              <li>Endereço *</li>
              <li>Sexo *</li>
            </ul>
          </div>
        </div>
        <p className="small text-center">* Estes dados serão solicitados de acordo com a configuração do programa de fidelidade de cada estabelecimento.</p>
        <label className={`${styles['check-form']} d-flex align-items-center justify-content-center`}>
          <input readOnly={true} type="checkbox" checked={true} className="option-checkbox" onClick={() => showModalPrivacidade(true)}/>
          <span>Autorizo o uso dos meus dados</span>
        </label>
      </div>
    </div>
  </>);

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {isLoading && <NProgress/>}
    <ModalPrivacidade show={isOpenModalPrivacidade} setShow={showModalPrivacidade}/>
    <div className="contents mt-5">
      <div className={styles["alterar-container"]}>
        <div className="mx-auto text-center">
          <h2 className="text-gray-1 fs-30">Meus Dados</h2>
          <p>Aqui você pode alterar configurações de notificação, <br/>alterar seus dados pessoais e excluir sua conta!</p>
          <Link className="px-5 btn btn-bg-purple-3 text-white" to="/dashboard">
            Voltar para o início
          </Link>
        </div>
        <hr className="dashed my-4" />
        <div className={styles["options-container"]}>
          <div className={styles["tabs"]}>
            <div className={`${styles.option} ${tab === "DadosCadastrais" ? "" : styles.unselected}`} onClick={() => setTab('DadosCadastrais')}>Dados cadastrais</div>
            <div className={`${styles.option} ${tab === "Notificacoes" ? "" : styles.unselected}`} onClick={() => setTab('Notificacoes')}>Notificações</div>
            <div className={`${styles.option} ${tab === "Privacidade" ? "" : styles.unselected}`} onClick={() => setTab('Privacidade')}>Privacidade</div>
          </div>

          {tab === 'DadosCadastrais' && DadosCadastrais}
          {tab === 'Notificacoes'    && Notificacoes}
          {tab === 'Privacidade'     && Privacidade}
        </div>
      </div>
    </div>
  </>);
}
export default MeusDados;
