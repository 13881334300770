import React from "react";
import { format, parse } from "date-fns";
import { filter, find } from "lodash";
import { useAuth } from "@Contexts/Auth";
import styles from "./styles.module.scss";

const Indica = props => {
  const { 
    parceiro, 
    user 
  } = useAuth();

  const ofertas = filter(parceiro.ofertas, (item) => !!item.oferta_indicacao);

  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {ofertas.length > 0 && (<>
      <div className="border-box mb-3">
        <div className="text-center">
          <p className="fw-500">Indique um amigo e ganhe!</p>
          <p>Para indicar e ganhar é muito simples, basta você compartilhar o link da oferta com seus amigos. Se seu amigo ativar a oferta, você pode ganhar pontos, e se ele resgatar o prêmio no estabelecimento, você pode ganhar mais pontos!</p>
          <p>Confira abaixo as ofertas ativas e suas recompensas.</p>

        </div>
        <table className={`table ${styles.table}`}>
          <thead>
            <tr>
              <th></th>
              <th>Oferta</th>
              <th>Recompensa p/ amigo</th>
              <th>Recompensa p/ você</th>
              <th>Disponível Até</th>
            </tr>
          </thead>
          <tbody>
            {ofertas.map((item, key) => { 
              const premio = find(parceiro.brindes, {id_premio: item.id_premio});
              const premioAtivacao = item.oferta_indicacao.id_premio_ativacao ? find(parceiro.brindes, {id_premio: item.oferta_indicacao.id_premio_ativacao}) : false;
              const premioResgate  = item.oferta_indicacao.id_premio_resgate  ? find(parceiro.brindes, {id_premio: item.oferta_indicacao.id_premio_resgate})  : false;
              const urlOferta = `https://ofertas.fidelizii.com.br/${parceiro.slug}/${item.id_oferta}?ci=${user.codigo_indicacao}`;

              return (<React.Fragment key={key}>
                <tr>
                  <td><i className="fa fa-ticket"></i></td>
                  <td><a className="text-purple-3" href={urlOferta} rel="noreferrer nofollow" target="_blank">{item.nome} <i className="fa fa-arrow-up-right-from-square"/></a></td>
                  <td>{premio.nome}</td>
                  <td rowSpan={2}>
                    {premioAtivacao !== false && <p className="mb-0">Ativação: {premioAtivacao.nome} </p>}
                    {premioResgate !== false && <p className="mb-0">Resgate: {premioResgate.nome} </p>}
                    {premioAtivacao === false && item.oferta_indicacao.recompensa_pontos_ativacao > 0 && (
                      <p className="mb-0">Ativação: {item.oferta_indicacao.recompensa_pontos_ativacao} ponto(s)</p>
                    )}
                    {premioResgate === false && item.oferta_indicacao.recompensa_pontos_resgate > 0 && (
                      <p className="mb-0">Resgate: {item.oferta_indicacao.recompensa_pontos_resgate} ponto(s)</p>
                    )}
                  </td>
                  <td>
                    {new Date(item.data_final).getFullYear() === 2999 && <>-</>}
                    {new Date(item.data_final).getFullYear() !== 2999 && <>{format(parse(item.data_final, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</>}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <div className="d-flex align-items-center">
                      <b className="me-3 fw-500">Compartilhe:</b> 
                      <a className="text-purple-3 fs-16 me-2" href={`https://www.facebook.com/sharer/sharer.php?u=${urlOferta}`} target="blank">
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a className="text-purple-3 fs-16 me-2" href={`https://www.linkedin.com/sharing/share-offsite/?url=${urlOferta}`} target="blank">
                        <i className="fab fa-linkedin"></i>
                      </a>
                      <a className="text-purple-3 fs-16 me-2" href={`http://twitter.com/share?text=Compartilhe e ganhe também &url=${urlOferta} &hashtags=fidelizii`} target="blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="text-purple-3 fs-16 me-2" href={`mailto:emaildo@amigo?subject=Compartilhe e ganhe  & body=${urlOferta} `}>
                        <i className="fa fa-envelope"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </React.Fragment>)
            })}
          </tbody>
        </table>
      </div>
    </>)}
  </>);
};

export default Indica;
