
export default function SocialMedia(props) {
  return (
    <div className={props.className}>
      { (props.whatsapp || false) && <a 
          href={props.whatsapp} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-whatsapp"></i></a> }

      { (props.facebook || false) && <a 
          href={props.facebook} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-facebook"></i></a> }

      { (props.twitter || false) && <a 
          href={props.twitter} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-twitter"></i></a> }

      { (props.instagram || false) && <a 
          href={props.instagram} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-instagram"></i></a> }
      
      { (props.youtube || false) && <a 
          href={props.youtube} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-youtube"></i></a> }

      { (props.vimeo || false) && <a 
          href={props.vimeo} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-vimeo-v"></i></a> }

      { (props.pinterest || false) && <a 
          href={props.pinterest} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-pinterest-p"></i></a> }

      { (props.linkedin || false) && <a 
          href={props.linkedin} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fab fa-linkedin"></i></a> }

      { (props.site || false) && <a 
          href={props.site} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fa fa-globe"></i></a> }

      { (props.blog || false) && <a 
          href={props.blog} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fa fa-blog"></i></a> } 
      { (props.email || false) && <a 
          href={props.email} 
          target="_blank" 
          rel={`noopener noreferrer ${props.rel}`}
        ><i aria-hidden className="fa fa-envelope"></i></a> } 
    </div>
  )
}
