import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { parseISO, format, isValid } from 'date-fns';
import { find } from "lodash";

import useStateWithValidation from '@Hooks/useStateWithValidation';
import Loading from '@Components/Loading';
import Tooltip from "@Components/Tooltip";
import WebApi from '@Services/WebApi';

import { 
  validarCPF,
  validarEmail, 
  validarNomeCompleto, 
  validarCelular, 
  validarData, 
  validarSexo, 
  convertStringToDate
} from "@Services/Helpers";


const FormCadastroCliente = ({cliente, questoes, onChange, className, showCpf}) => {
  const [cpf, setCpf, isCpfValid]                                  = useStateWithValidation(validarCPF,cliente?.cpf || '')
  const [nomeCompleto, setNomeCompleto, isNomeCompletoValid]       = useStateWithValidation(validarNomeCompleto,cliente?.nome || '')
  const [email, setEmail, isEmailValid]                            = useStateWithValidation(validarEmail, cliente?.email || '');
  const [dataNascimento, setDataNascimento, isDataNascimentoValid] = useStateWithValidation(validarData,convertStringToDate(cliente?.dados_adicionais?.data_nascimento) ? format(convertStringToDate(cliente?.dados_adicionais?.data_nascimento),'dd/MM/yyyy') : '');
  const [celular, setCelular, isCelularValid]                      = useStateWithValidation(validarCelular,cliente?.celular || cliente?.dados_adicionais?.celular || '');
  const [sexo, setSexo, isSexoValid]                               = useStateWithValidation(validarSexo,cliente?.dados_adicionais?.sexo || '');
  const [sexoOutro, setSexoOutro]                                  = useState(cliente?.dados_adicionais?.sexo_outro || '')
  const [cep, setCep, isCepValid]                                  = useStateWithValidation((value) => value.replace(/[^\d]/g, "").length === 8,cliente?.dados_adicionais?.cep || '');
  const [endereco, setEndereco, isEnderecoValid]                   = useStateWithValidation((value) => value !== '',cliente?.dados_adicionais?.endereco || '');
  const [bairro, setBairro, isBairroValid]                         = useStateWithValidation((value) => value !== '',cliente?.dados_adicionais?.bairro || '');
  const [estado, setEstado, isEstadoValid]                         = useStateWithValidation((value) => !!value?.value && Number(value.value) > 0, {value: cliente?.dados_adicionais?.id_estado || '', label: ''});
  const [cidade, setCidade, isCidadeValid]                         = useStateWithValidation((value) => !!value?.value && Number(value.value) > 0, {value: cliente?.dados_adicionais?.id_cidade || '', label: ''});

  const [loadingAddress, setLoadingAddress] = useState(false);
  const [listEstados, setListEstados]     = useState([]);
  const [listCidades, setListCidades]     = useState([]);

  const questao_data_nascimento = (questoes.questao_data_nascimento.includes('obrigatorio'));
  const questao_celular         = (questoes.questao_celular.includes('obrigatorio'));
  const questao_sexo            = (questoes.questao_sexo.includes('obrigatorio'));
  const questao_localizacao     = (questoes.questao_localizacao.includes('obrigatorio'));
  const hasDadoObrigatorio      = (questao_data_nascimento || questao_celular || questao_sexo || questao_localizacao);
  const isClienteFidelizi       = !!cliente?.id_cliente;


  //////////////////////////////////////////////////////////////////////////////////////
  // handlers
  //////////////////////////////////////////////////////////////////////////////////////
  function handleEstado(value) {
    if (value.value === estado.value) return;

    setEstado(value);
    setCidade({value: '', label: ''});
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // effects
  //////////////////////////////////////////////////////////////////////////////////////

  // Buscar endereço pelo cep
  useEffect(() => {
    if (isCepValid && (!isEnderecoValid || !isBairroValid || !isEstadoValid || !isCidadeValid)) {
      setLoadingAddress(true);
      axios.get(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json`).then(res => {
        if ('erro' in res.data) {return;};
        if (endereco === '') {setEndereco(res.data.logradouro);}
        if (bairro === '') {setBairro(res.data.bairro);}
        if (estado.value === '') {
          let state = find(listEstados,(item, index) => item.label.includes(`${res.data.uf} -`));
          setEstado(state);
        }
      }).catch(err => {

      }).finally(() => setLoadingAddress(false));
    }
  },[isCepValid])

  // Buscar Estados
  React.useEffect(() => {
    WebApi.get('meu/estados')
      .then(response => {
        const states = response.data.estados.map((estado) => ({
          value: estado.id,
          label: `${estado.uf} - ${estado.nome}`
        }))
        setListEstados(states);
        if (cliente?.dados_adicionais?.id_estado) {
          setEstado(find(states, {value: cliente?.dados_adicionais?.id_estado}));
        }
      });    

  }, []);

  //Buscar Cidades
  React.useEffect(() => {
    if (estado.value > 0) {
      setListCidades([])
      WebApi.get(`meu/cidades/${estado.value}`).then(response => {
        const cities = response.data.cidades.map((cidade) => ({
          value: cidade.id,
          label: cidade.nome
        }))
        setListCidades(cities);
        if (cliente?.dados_adicionais?.id_cidade) {
          setCidade(find(cities, {value: cliente?.dados_adicionais?.id_cidade}));
        }
      });
    }
  }, [estado.value])

  // Retornar valores
  React.useEffect(() => {
    let data = {
      nome_completo: {
        value   : nomeCompleto,
        required: true,
        isValid : isNomeCompletoValid,
      },
      email: {
        value   : email,
        required: true,
        isValid : isEmailValid,
      },
      data_nascimento: {
        value   : dataNascimento.split('/').reverse().join('-'),
        required: questao_data_nascimento,
        isValid : isDataNascimentoValid,
      },
      celular: {
        value   : celular,
        required: questao_celular,
        isValid : isCelularValid,
      },
      sexo: {
        value   : sexo,
        required: questao_sexo,
        isValid : isSexoValid,
      },
      sexo_outro: {
        value   : sexoOutro,
        required: sexo === 'O' ? true : false,
        isValid : sexoOutro !== '' ? true : false,
      },
      cep: {
        value   : cep,
        required: questao_localizacao,
        isValid : isCepValid,
      },
      endereco: {
        value   : endereco,
        required: questao_localizacao,
        isValid : isEnderecoValid,
      },
      bairro: {
        value   : bairro,
        required: questao_localizacao,
        isValid : isBairroValid,
      },
      estado: {
        value   : estado?.value,
        required: questao_localizacao,
        isValid : isEstadoValid,
      },
      cidade: {
        value   : cidade?.value,
        required: questao_localizacao,
        isValid : isCidadeValid,
      },
      formIsValid: isNomeCompletoValid && isEmailValid && 
        (!questao_data_nascimento ? true : isDataNascimentoValid) &&
        (!questao_celular ? true : isCelularValid) &&
        (!questao_sexo ? true : isSexoValid) &&
        (!questao_localizacao ? true : isCepValid) &&
        (!questao_localizacao ? true : isEnderecoValid) &&
        (!questao_localizacao ? true : isBairroValid) &&
        (!questao_localizacao ? true : isEstadoValid) &&
        (!questao_localizacao ? true : isCidadeValid)
    };
    if (showCpf) {
      data.cpf = {
        value   : cpf,
        required: !!showCpf,
        isValid : isCpfValid,
      }
    }
    onChange(data);
  },[cpf,nomeCompleto,email,dataNascimento,celular,sexo,sexoOutro,cep,endereco,bairro,estado,cidade]);

  //////////////////////////////////////////////////////////////////////////////////////
  // render()
  //////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className={`${styles.formCadastro} ${className || ''}`}>
      <div className="d-block">
        <div className={styles.group}>
          <h2 className={styles.title}>Dados Essenciais</h2>
          {showCpf && (<>
            <div className="form-group mb-3">
              <label>CPF <span className="text-gray-3 small">(Obrigatório)</span></label>
              <NumberFormat 
                className="form-control" 
                value={cpf}
                onChange={(event) => {if (!isClienteFidelizi) setCpf(event.target.value);}}
                readOnly={!!isClienteFidelizi}
                format="###.###.###-##" 
                placeholder="___.___.___-__" 
                mask={['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_']}
              />
            </div>
          </>)}
          <div className="form-group mb-3">
            <label>Nome completo <span className="text-gray-3 small">(Obrigatório)</span></label>
            <input 
              className="form-control" 
              value={nomeCompleto}
              onChange={(event) => {if (!isClienteFidelizi) setNomeCompleto(event.target.value.toUpperCase());}}
              readOnly={!!isClienteFidelizi}
            />
          </div>
          <div className="form-group mb-3">
            <label>Email <span className="text-gray-3 small">(Obrigatório)</span></label>
            <input 
              className="form-control" 
              value={email}
              onChange={(event) => {if (!isClienteFidelizi) setEmail(event.target.value);}}
              readOnly={!!isClienteFidelizi}
            />
          </div>
        </div>
        
        { hasDadoObrigatorio && (<>
          <div className={styles.group}>
            <h2 className={styles.title}>Dados Adicionais</h2>
            

            { questao_data_nascimento && (<div className='form-group mb-3'>
              <label>Data de Nascimento <span className="text-gray-3 small">(Obrigatório)</span></label>
              <NumberFormat 
                className="form-control"
                format="##/##/####"
                placeholder="DD/MM/AAAA" 
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask={['D', 'D', 'M', 'M', 'A', 'A', 'A', 'A']}
              />
            </div>)}

            { questao_celular && (<div className='form-group mb-3'>
              <label>Celular <span className="text-gray-3 small">(Obrigatório)</span></label>
              <PhoneInput
                inputClass='form-control w-100'
                onlyCountries={['br']}
                country={'br'}
                value={celular}
                onChange={(celular) => setCelular(celular)}
                placeholder={'(99) 999999999'}
                countryCodeEditable={false}
                disableDropdown={true}
              />
            </div>
            )}
            
            { questao_sexo && (
              <>
                <div className='form-group mb-3'>
                  <label>Gênero <span className="text-gray-3 small">(Obrigatório)</span></label>
                  <select 
                    className='form-control'
                    value={sexo}
                    onChange={(event) => setSexo(event.target.value)}
                  >
                    <option value="" disabled={true}>Selecione</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="O">Outro</option>
                    <option value="NA">Prefiro não dizer</option>
                  </select>
                </div>
                {
                  sexo === "O" && (
                    <div className='form-group mb-3 animate fadeIn'>
                      <label>Qual? <span className="text-gray-3 small">(Obrigatório)</span></label>
                      <input 
                        className="form-control"
                        value={sexoOutro}
                        onChange={(event) => setSexoOutro(event.target.value)}
                      />
                    </div>
                  )
                }
              </>
            )}

            { questao_localizacao && (<>
              <div className='form-group mb-3'>
                <label>CEP <span className="text-gray-3 small">(Obrigatório)</span></label>
                <div className="d-block position-relative">
                  <NumberFormat 
                    className="form-control"
                    format="#####-###"
                    value={cep}
                    placeholder={"_____-___"}
                    onChange={(e) => setCep(e.target.value)}
                    mask={'_'}
                  />
                  {loadingAddress && <Loading className={styles.microloading} size={20}/>}
                </div>
              </div>
              <div className='form-group mb-3'>
                <label>Endereço <span className="text-gray-3 small">(Obrigatório)</span></label>
                <input 
                  className="form-control"
                  value={endereco}
                  onChange={(event) => setEndereco(event.target.value)}
                  disabled={loadingAddress}
                />
              </div>
              <div className='form-group mb-3'>
                <label>Bairro <span className="text-gray-3 small">(Obrigatório)</span></label>
                <input 
                  className="form-control"
                  value={bairro}
                  onChange={(event) => setBairro(event.target.value)}
                  disabled={loadingAddress}
                />
              </div>
              <div className='form-group mb-3'>
                <label>Estado <span className="text-gray-3 small">(Obrigatório)</span></label>
                <Select
                  menuPlacement="top"
                  options={listEstados}
                  value={estado}
                  onChange={handleEstado}
                  isDisabled={loadingAddress}
                />
              </div>
              <div className='form-group mb-3'>
                <label>Cidade <span className="text-gray-3 small">(Obrigatório)</span></label>
                <Select 
                  menuPlacement="top"
                  options={listCidades}
                  value={(estado.value && listCidades.length === 0) ? {value: '', label: 'Carregando...'} : cidade}
                  onChange={(value) => setCidade(value)}
                  isDisabled={loadingAddress}
                />
              </div>
            </>)}

            { cliente?.id_cliente && (<>
              <div className={styles.clienteExiste}>
                <Tooltip text="Nós puxamos os dados cadastrais de quem já participa do Fidelizi para maior agilidade! 😉">
                  <p className='fs-9 fw-500 mt-2 cursor-pointer'><i className="fa fa-question-circle"></i> Por que alguns dados já estão preenchidos?</p>
                </Tooltip>
              </div>
            </>)}
          </div>
        </>)}
      </div>
    </div>
  )
}

export default React.memo(FormCadastroCliente);