import styles from './style.module.scss';

export default function Tablet(props) {

  return (
    <>
    <div className={[styles.container, props.className].join(" ")}>
      <div className={styles.tab}>
        <img
          src="/assets/img/tablet.webp"
          alt="Tablet Programa de Fidelidade"
        />
      </div>
      <div className={styles.Tablet}>
        <div className={styles.tsOverlay}>
          <svg viewBox="0 0 1024 600">
            <defs>
              <filter width="110%" height="100%">
                <feGaussianBlur stdDeviation="32" result="blur" />
              </filter>
              <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="100%">
                <stop offset="0%" style={{'stopColor':'rgb(0,0,0)','stopOpacity':'0.25'}} />
                <stop offset="7.5%" style={{'stopColor':'rgb(0,0,0)','stopOpacity':'0.65'}} />
              </linearGradient>
            </defs>
            <g className="fzTopo">
              <rect fill={props.corBarraSuperior} className="fzbackground" width="1024" height="40" />
              <rect className={styles.fzShadow} y="37" width="1024" height="3" />
              <image width="1096" height="75" transform="translate(-36 -37)" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABEgAAABLCAYAAABjoLJrAAAACXBIWXMAAAsSAAALEgHS3X78AAAEuElEQVR4Xu3dQW7bMBBA0bGT9v73TWJ1EROaEJTdlUhn3gMEOSqdttrpg6Qu27YFAAAAQGXXZwMAAAAAfjuBBAAAAChPIAEAAADKE0gAAACA8gQSAAAAoDyBBAAAAChPIAEAAADKE0gAAACA8gQSAAAAoDyBBAAAAChPIAEAAADKE0gAAACA8gQSAAAAoDyBBAAAAChPIAEAAADKE0gAAACA8gQSAAAAoDyBBAAAAChPIAEAAADKE0gAAACA8gQSAAAAoDyBBAAAAChPIAEAAADKE0gAAACA8t6fDVjA5dkAAAAAYHnbswEzrRZIjmKISAIAAACva4vxs/0y0WSFQNLfoEt3HhFMAAAAYF19+MjP8Vs6j65PMTuQ9DHkcnCtHw8AAACsL0ePbXDuY8m0SDIzkPRR5Nkx+i4AAACwnj50bE+ONmZaJJkVSHL0uN4/53N/7X+W3QAAAABr6WeL3NL5NrjWf+80MwJJjhw5hLwdnEczSYQSAAAAWFcfRnIg+erOcT9f08+nzySZEUgifgaPFkPe4vvfkz+3P2vj2nfz7wEAAADWMNpzpM0QaUHk8/75ev/c3GJ/zj81jkScH0j6uJEDyfv9+JM+t2ByNJMEAAAAWM/RzJHP+H7O/4yIj8H4PHPk1FkkZweSJseRdrzFdxzpjzajxH4kAAAAsL7RviNf9+Mj9uf7NqYtrxlFktPMCCQ5cPRLbFok+Rt7IMmzSPJNFEgAAABgPTmQtM1Y2+yRPPHhFt/P+7d05Gf+UyPJjEAS8XipzWjJTYsnltgAAADA+vLymrbfSLveZoyMVotETIgjEfMCSdPPJumP0SuABRIAAABYW15ec03no+f/iMnP+rMDSb8uabSJSztf0nnqTQMAAAAeOnq2P3r+j3SeYlYgyf/pfMPyu5Db2qRI1+xBAgAAAGt7tAdJe8VvbgA5kuTvn2pGINliX0+Uw8g19h1t27jtfs1bbAAAAOA19KtF+rfYtOMrHX0oOT2SzAgkET9vUls608eRdq2PI5bYAAAAwNr6JTT9LJLP2CNJfotNP5vkNGcHkjZ7pH3ONymPyWWpvb2m7Xib44hQAgAAAOsYLZVp4aPFkLbM5it+LrmZutTm7EDS5Ckzt+56nnpzNHNEGAEAAIB19cts+kkSo3Med7rLts35e2OPHKNX+R691lcYAQAAgNfRh5L8Rpv+7TZtAsWUSDJ7BknEvg9JW37Tfn6054hQAgAAAOvqA0c/k+ToGH33FLMCScQeRLbu5362iD1HAAAA4DXl2NEHkFEUmRJHIuYtscmOZoc8iiFCCQAAAKzrUWw4iiFTA8UKgSQ7Ch+CCAAAALyuo/iwTJSYucRmZHRj8jIcAAAA4DUt/Wy/WiAZWfoGAgAAAK/v+mwAAAAAwG8nkAAAAADlCSQAAABAeQIJAAAAUJ5AAgAAAJQnkAAAAADlCSQAAABAeQIJAAAAUJ5AAgAAAJQnkAAAAADlCSQAAABAeQIJAAAAUJ5AAgAAAJQnkAAAAADlCSQAAABAeQIJAAAAUJ5AAgAAAJQnkAAAAADlCSQAAABAeQIJAAAAUJ5AAgAAAJQnkAAAAADlCSQAAABAeQIJAAAAUN4/46s6awihvykAAAAASUVORK5CYII=" />
              <g className={styles.logo} transform="translate(0 -1)">
                <path d="M977.12,17.85c-1.22,0-2.44,0-3.65,0a5.87,5.87,0,0,1-1.66-.31,1.5,1.5,0,0,1-1-1.56A1.52,1.52,0,0,1,972,14.44a6,6,0,0,1,1.68-.23c2.5,0,5,0,7.5,0a7.48,7.48,0,0,1,1.15.12,1,1,0,0,1,.86,1.36,4.48,4.48,0,0,1-.76,1.41c-1.8,2.23-3.64,4.43-5.47,6.64l-.41.51.57,0c1.26,0,2.53,0,3.79,0a5,5,0,0,1,1.7.36,1.54,1.54,0,0,1,.94,1.73,1.51,1.51,0,0,1-1.16,1.4,5.18,5.18,0,0,1-1.45.24c-2.88,0-5.77,0-8.65-.05-.17,0-.34,0-.5-.06-1-.12-1.34-.73-1-1.8a3.48,3.48,0,0,1,.65-1.05q2.72-3.37,5.47-6.71l.3-.38Z" />
                <path d="M948.28,27.24c-1.83,1.61-4.44.85-5.67-1.37a5.29,5.29,0,0,1,.9-6.15,3.59,3.59,0,0,1,4.5-.43l.2.08c0-.19,0-.34,0-.5,0-.8,0-1.6,0-2.4.05-1,.52-1.43,1.35-1.41a1.07,1.07,0,0,1,1.09.88,3.83,3.83,0,0,1,.12,1q0,4.61,0,9.21a5.12,5.12,0,0,1-.08.87,1.13,1.13,0,0,1-.86,1,1.31,1.31,0,0,1-1.36-.46Zm-3.63-3.85a2,2,0,0,0,.19,1.15,1.76,1.76,0,0,0,1.5,1.12,1.6,1.6,0,0,0,1.51-.73,3,3,0,0,0-.1-3.22,1.59,1.59,0,0,0-2.5,0A2.94,2.94,0,0,0,944.65,23.39Z" />
                <path d="M932.13,22.45c0,1.05,0,2,0,3a8.61,8.61,0,0,1-.17,1.6,1.26,1.26,0,0,1-1.38,1A1.28,1.28,0,0,1,929.21,27a6.15,6.15,0,0,1-.2-1.41c0-3,0-6,0-9a6.38,6.38,0,0,1,.16-1.23,1.24,1.24,0,0,1,1.16-1.09c.67-.06,1.35-.09,2-.09,1.11,0,2.22,0,3.32.11a1.22,1.22,0,0,1,1.21,1.45A1.15,1.15,0,0,1,935.74,17a23.74,23.74,0,0,1-2.67.11c-.94,0-.94,0-.94.92,0,.4,0,.81,0,1.21s.11.41.42.41a23.83,23.83,0,0,1,2.81.11,1.25,1.25,0,0,1,1.15,1.65,1,1,0,0,1-.85.87,15.3,15.3,0,0,1-1.79.19C933.32,22.48,932.77,22.45,932.13,22.45Z" />
                <path d="M964.36,21.09c0,1.66,0,3.33,0,5a4,4,0,0,1-.14,1.09,1.18,1.18,0,0,1-1.21.89,1.2,1.2,0,0,1-1.25-.89,3,3,0,0,1-.15-.95c0-3.45,0-6.91,0-10.36a3,3,0,0,1,.15-.95,1.3,1.3,0,0,1,2.47,0,4.53,4.53,0,0,1,.13,1.1C964.37,17.73,964.36,19.41,964.36,21.09Z" />
                <path d="M955.1,24.09c1.5,0,3,0,4.49,0,.86,0,1.15-.27,1.16-1a4.38,4.38,0,0,0-6.32-4.1,5,5,0,0,0-1.71,7.37,5.09,5.09,0,0,0,6.43,1.23,4.08,4.08,0,0,0,1.17-.94,1.1,1.1,0,0,0,0-1.34,1,1,0,0,0-1.29-.27c-.23.08-.45.21-.68.29a9.68,9.68,0,0,1-1.54.46,1.89,1.89,0,0,1-2.27-1.67Zm1.25-3.41a1.72,1.72,0,0,1,1.75,1.66h-3.52A1.79,1.79,0,0,1,956.35,20.68Z" />
                <path d="M937.63,20.12a1.5,1.5,0,0,1,2.22-1.28,1.57,1.57,0,0,1,.86,1.62q0,3.09-.07,6.18a1.36,1.36,0,0,1-1.35,1.47,1.4,1.4,0,0,1-1.69-1.3,6.77,6.77,0,0,1-.07-1c0-.77,0-1.54,0-2.31C937.56,23.49,937.54,21.24,937.63,20.12Z" />
                <circle cx="939.12" cy="16.06" r="1.81" />
                <path d="M969.47,22.91c0,1.09,0,2.18,0,3.28,0,1.8-1.28,2.12-2.57,1.8a1.32,1.32,0,0,1-1-1.27c-.06-1.23-.05-2.46-.06-3.69,0-.69,0-1.39,0-2.08a8.3,8.3,0,0,1,.05-.87,1.47,1.47,0,0,1,1.33-1.34h.09c1.4-.2,2.1.45,2.14,1.87C969.44,21.37,969.48,22.91,969.47,22.91Z" />
                <circle cx="967.63" cy="16.06" r="1.81" />
                <path d="M987.87,23.43c0,1,0,1.94,0,2.91a1.52,1.52,0,0,1-.85,1.53,2.19,2.19,0,0,1-2.16-.14,1.57,1.57,0,0,1-.65-1.42c0-2,0-4,0-6a1.86,1.86,0,0,1,3-1.41,1.33,1.33,0,0,1,.64,1.12c0,1.12,0,2.25,0,3.37Z" />
                <circle cx="986.03" cy="16.06" r="1.81" />
                <path d="M992.37,27.19a1.81,1.81,0,1,1-1.44-2.11A1.82,1.82,0,0,1,992.37,27.19Z" />
                <path d="M992.6,23.56a1.37,1.37,0,0,1-2.66-.51,61.43,61.43,0,0,1,.45-9.18c.38-2,1.61-2.14,3.05-1.86h0c1.43.27,2.54.78,2.14,2.85A49.57,49.57,0,0,1,992.6,23.56Z" />
              </g>
              <text fill={props.corTexto} className={styles.title} transform="translate(20.5 26.01)">{props.parceiroNome}</text>
            </g>
            <g className="fzRodape">
              <rect fill="url(#grad1)" data-name="background" y="410" width="1024" height="190" />
              <text className={styles.chamada} transform="translate(15 466)">{props.programaChamada}</text>
              <text className={styles.descricao} transform="translate(15 532)">{props.programaDescricao}</text>
              <text className={styles.detalhes} transform="translate(15 580)" lengthAdjust="spacing">{props.programaObservacao}</text>
            </g>
          </svg>
        </div>
        <img src="https://s3-sa-east-1.amazonaws.com/fidelizii/admin/imagem/15741910475dd43fc7a3fc9.png" alt="Iniciar" className={styles.fzIniciar}/>
        <div className={styles.tsBackground}>
          <img src={props.fundoTelaInicial} alt={props.parceiroNome}/>
          {/* <video ng-if="ctrl.programa.visual.video" autoplay loop ng-src={props.video} style="border: none;">
            <source src={props.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </div>
      </div>
    </div>
    </>
  )
}