import React from "react";
import { format, parse } from "date-fns";
import { useAuth } from "@Contexts/Auth";
import { find } from "lodash";
import TimelineEventos from "@Components/Cliente/TimelineEventos";

const Brindes = props => {
  const { parceiro }                = useAuth();

  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {parceiro.cartela_atual.brindes_disponiveis.length > 0 && (<>
      <div className="border-box mb-3">
        <p className="text-center">Confira abaixo os brindes que você tem disponível para resgate!</p>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Brinde</th>
              <th>Categoria</th>
              <th>Resgatar Até</th>
            </tr>
          </thead>
          <tbody>
            {parceiro.cartela_atual.brindes_disponiveis.map((item, key) =>{ 
              const premio = find(parceiro.brindes, {id_premio: item.id_premio});
              return (<React.Fragment key={key}>
              <tr>
                <td><i className="fa fa-gift"></i></td>
                <td>{premio.nome}</td>
                <td>
                  {!premio.categoria && <>Brinde da Roleta</>}
                  {premio.categoria === 'premio_campanha' && <>Prêmio de Campanha</>}
                  {premio.categoria === 'premio_desafio' && <>Prêmio de Boas Vindas</>}
                </td>
                <td>{format(parse(item.date_validade, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MM/yyyy')}</td>
              </tr>
            </React.Fragment>)})}
          </tbody>
        </table>
      </div>
    </>)}
    <div className="border-box" style={{minHeight: "400px"}}>
      <div className="d-block d-md-flex align-items-center mb-5">
        <h3 className="text-purple-2 fw-bold text-left m-0">Histórico</h3>
      </div>
      <TimelineEventos atividades={'brindes'} eventosArray={['roleta_conquistados', 'roleta_resgatados', 'roleta_expirados', 'surpresa_conquistados', 'surpresa_resgatados', 'surpresa_expirados', 'campanha_conquistados', 'campanha_resgatados', 'campanha_expirados']} />
    </div>
  </>);
};

export default Brindes;
