import styles from './style.module.scss';
import { groupBy } from 'lodash';
import React, { useState } from 'react';
import { isBefore, isAfter, setHours, setMinutes } from 'date-fns';

const HorarioFuncionamento = (props) => {
  const [show, setShow] = useState(false);
  const handleShow      = () => setShow(true);
  const handleClose     = () => setShow(false);

  const tipo     = props.horarios.tipo;
  const horarios = groupBy(props.horarios.personalizado, 'tipo');
  const mode     = props.mode || 'simple'
  const hoje     = new Date();


  const weekslug = [
    'domingo',
    'segunda',
    'terca',
    'quarta',
    'quinta',
    'sexta',
    'sabado'
  ];
  const weekday  = {
    segunda: { 
      simple  : 'SEG',
      full    : 'Segunda',
      complete: 'Segunda-Feira',
      getDay  : 0
    },
    terca  : { 
      simple  : 'TER',
      full    : 'Terça',
      complete: 'Terça-Feira',
      getDay  : 1
    },
    quarta : { 
      simple  : 'QUA',
      full    : 'Quarta',
      complete: 'Quarta-Feira',
      getDay  : 2
    },
    quinta : { 
      simple  : 'QUI',
      full    : 'Quinta',
      complete: 'Quinta-Feira',
      getDay  : 3
    },
    sexta  : { 
      simple  : 'SEX',
      full    : 'Sexta',
      complete: 'Sexta-Feira',
      getDay  : 4
    },
    sabado : { 
      simple  : 'SÁB',
      full    : 'Sábado',
      complete: 'Sábado',
      getDay  : 5
    },
    domingo: { 
      simple  : 'DOM',
      full    : 'Domingo',
      complete: 'Domingo',
      getDay  : 6
    },
    // Função pra poder trazer as labels acima de acordo com uma data especificada puxando seu dia da semana
    get: (date) => {date = date || new Date(); return weekday[ weekslug[ date.getDay() ] ].getDay }
  };
  
  // const horarioHoje = horarios?.principal?.[weekday.get()];
  // Horas: 
  // Fechado ⋅ Abre às 18:00
  //  · Mais horários de funcionamento
  // Horários que serão comparado pra saber se está aberto ou não hoje
  let horariosAbertura = [];

  
  // Se abre primeiro turno hoje, adiciona o primeiro turno em horariosAbertura [0]
  const horarioHoje = horarios?.principal?.[weekday.get()];
  if (horarioHoje) {
    horariosAbertura.push({
      dtStart: new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), horarioHoje.hora_inicio.substring(0,2), horarioHoje.hora_inicio.substring(3,5)),
      dtEnd: new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), horarioHoje.hora_fim.substring(0,2), horarioHoje.hora_fim.substring(3,5))
    });
  }

  // Se abre segundo turno hoje, adiciona o segundo turno em horariosAbertura [1]
  const secundarioHoje = horarios?.customizado?.[weekday.get()];
  if (secundarioHoje && secundarioHoje.hora_inicio !== secundarioHoje.hora_fim) {
    horariosAbertura.push({
      dtStart: new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), secundarioHoje.hora_inicio.substring(0,2), secundarioHoje.hora_inicio.substring(3,5)),
      dtEnd: new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), secundarioHoje.hora_fim.substring(0,2), secundarioHoje.hora_fim.substring(3,5))
    });
  }


  // Variavel que avisa em qual turno está atualmente
  let turno = 'fechado';

  if (horarioHoje.ativo && isBefore(hoje, horariosAbertura[0].dtEnd) && isAfter(hoje, horariosAbertura[0].dtStart)) {
    turno = 'primeiro'
  }
  if (horariosAbertura[1]) {
    if (secundarioHoje.ativo && isBefore(hoje, horariosAbertura[1].dtEnd) && isAfter(hoje, horariosAbertura[1].dtStart)) {
      turno = 'segundo'
    }
    if (isBefore(hoje, horariosAbertura[1].dtStart) && isAfter(hoje, horariosAbertura[0].dtEnd)) {
      turno = 'entre'
    }
  }



  // /** * Variaveis que serão utilizadas em horário de funcionamento */
  
  const Aberto = ({horario}) => {
    let fechamento = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), horario.hora_fim.substring(0,2), horario.hora_fim.substring(3,5));
    let segundosFaltando = Math.ceil((fechamento - hoje) / 1000) ;

    return (<>
      { (horario.ativo === true && segundosFaltando >= 3600) && 
        <b className={styles.opened}>Aberto Agora</b>
      }
      { (horario.ativo === true && segundosFaltando > 0 && segundosFaltando < 3600) && 
        <b className={styles.later}>Fechará em breve</b>
      }
      { (horario.ativo === false || segundosFaltando <= 0 ) &&
        <b className={styles.closed}>Fechado</b>
      }
    </>)

  } 


  return (
    <div className={styles.container}>
      <div className="d-block d-lg-flex text-center text-lg-left align-items-center">
        {(turno === 'primeiro') && <Aberto horario={horarioHoje}/>}
        {turno === 'segundo' && <Aberto horario={secundarioHoje}/>}
        {turno === 'fechado' && <b className={styles.closed}>Fechado</b>}
        {turno === 'entre' && <>
          <b className={styles.closed}>Fechado</b> <span className={styles.abreAs}>- Abre às {secundarioHoje.hora_inicio.substring(0,2)}:{secundarioHoje.hora_inicio.substring(3,5)}</span>
        </>}
        
        <div 
          className={styles.trigger}
          onMouseEnter={handleShow}
          onMouseLeave={handleClose}
        >
          <i className="fa fa-angle-down"></i>
        </div>
        <div className={[styles.popup, show ? styles.open : undefined].join(" ")}>
          <table className={styles.horarios}>
            <tbody>
              {horarios.principal.map( (horario, i) => { 
                let segundo = horarios?.customizado?.[i];
                
                return (
                <tr key={i} className={`${horario.ativo ? '' : styles.horarioInactive} ${( weekslug[ hoje.getDay() ] == horario.dia_semana ) ? styles.horarioActive : undefined}`} >
                  <td>
                    {weekday[horario.dia_semana][mode]}<br/>
                  </td>
                  <td>
                    {!horario.ativo && "Fechado"}
                    {horario.ativo && <>
                      <div className="d-flex">
                        <div className={styles.horario}>{horario.hora_inicio.substring(0,5) + '–' + horario.hora_fim.substring(0,5)}</div>
                        { (segundo?.ativo && segundo?.hora_inicio !== segundo?.hora_fim) && <>,<div className={`${styles.horario} ${styles.secundario}`}>{segundo?.hora_inicio.substring(0,5) + '–' + segundo?.hora_fim.substring(0,5)}</div></>}
                      </div>
                    </>}
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}


export default HorarioFuncionamento;