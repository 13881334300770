import React from 'react';
import Loading from '@Components/Loading';

// interface ComponentProps {
//   loading? : boolean;
//   color?: any;
// }
  
const Button = ({loading = false, color = '#ffffff', ...props}) => {
  
  return (<>
    <button {...props} disabled={loading}>
      <>{loading ? <Loading size={20} color={color}/> : props.children}</>
    </button>
  </>)
}
  
export default Button;