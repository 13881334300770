import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import '@Styles/global.scss';


if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    // debug: process.env.REACT_APP_ENV === 'local',
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: ['Non-Error promise rejection captured', "Illegal invocation"]
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<>
  {process.env.REACT_APP_ENV === 'production' && <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}><App/></Sentry.ErrorBoundary>}
  {process.env.REACT_APP_ENV !== 'production' && <App/>}
</>);